.mysubscription {
  margin-bottom: 90px;
}

.mysubscription .mySubsciptionBox .plansBx {
  height: auto;
}

.mysubscription .notificationCard {
  height: 650px;
}

.notificationCard .DebitCreditCard,
.NotificationDate {
  margin: 7px 0px;
}

.DebitCreditCard {
  font-weight: 600;
}

.DownloadInvoice {
  text-decoration: none;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
}

.mySubsciptionBox .plansBx ul li:last-child {
  margin-top: 50px;
  font-size: 17px;
  font-weight: 500;
}

.mysubscription .notificationCard ul li {
  border-bottom: 1px solid #d9d9d9;
}

.mySubsciptionBox .plansBx .planHaeading {
  text-align: left;
}

.mySubsciptionBox .ReactModal__Overlay {
  background-color: #fff !important;
}
