.MatchesPage {
  font-family: "Montserrat";
}
/*-- Profile card --*/

.profileCardFtr p {
  text-decoration: none;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 14px;
  align-items: center;
  display: flex;
  gap: 7px;
  cursor: pointer;
}

.profileCardFtr.Sent {
  display: flex;
  position: initial;
  bottom: 0;
  border-top: 1px solid #d9d9d9;
  justify-content: space-between;
  gap: 40px;
  padding-top: 15px;
  padding-right: 0px;
  padding-left: 0px;
}

.profileCardFtr.Recived {
  display: flex;
  position: initial;
  bottom: 0;
  border-top: 1px solid #d9d9d9;
  justify-content: space-between;
  gap: 40px;
  padding-top: 15px;
  padding-right: 0px;
  padding-left: 0px;
}

.profileCard {
  display: flex;
  max-height: 240px;
  box-shadow: 4px 4px 20px 20px #f3f3f3;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.profileImage {
  min-width: 240px;
  max-width: 240px;
  height: auto;
  overflow: hidden;
}

.profileCard .profileImage img {
  width: 100%;
}

.profileCardFtr {
  display: flex;
}

.profileDetail {
  width: 100%;
  position: relative;
  padding: 25px;
}

.profileCardFtr {
  display: flex;
  position: absolute;
  bottom: 0;
  /* border-top: 1px solid #d9d9d9; */
  right: 50px;
  left: 25px;
  justify-content: center;
  gap: 40px;
  padding-top: 15px;
  padding-right: 30px;
  padding-left: 30px;
}

.profileCardFtr img,
.likeAge img {
  width: 22px;
}

.blockedCardFtr img {
  width: 15px;
}
