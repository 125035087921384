.settingPageBox .commanheading {
  font-family: "Quiche Display Regular", "Montserrat";
  background: -webkit-linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hideDeleteProfile .commanheading {
  font-family: "Quiche Display Regular", "Montserrat";
  background: -webkit-linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.settingLabel {
  margin: 20px 0px;
  font-size: 22px;
}

.settingLabel {
  margin: 20px 0px;
  font-size: 22px;
}

.settingPageBox {
  margin: 80px 0px;
}

.settingForm .inputBox input {
  margin-bottom: 0px;
  border: none;
  border-radius: 0px;
  padding: 0;
}

.inputBox {
  position: relative;
  border-bottom: 2px solid red;
}

.sign-in {
  color: red;
}

.hideDeleteProfile {
  margin-bottom: 40px;
}

.button {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  color: #fff;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 40px;
  border: 1px solid #fff;
  font-family: "Montserrat";
  font-weight: 600;
  height: -moz-fit-content;
  height: fit-content;
  width: 190px;
  text-align: center;
  width: 230px;
  cursor: pointer;
}

.smalltHeading {
  font-weight: 700;
}

.shortHeading {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 15px;
}

.box1 {
  /* margin-bottom: 40px; */
}

.box2 {
  margin-top: 50px;
  padding-top: 50px;
  position: relative;
}

.box2::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  content: " ";
  background: red;
  height: 1px;
  width: calc(100% + 30%);
}

.ReportUserPage .group-2 {
  width: 75%;
}

.group-2 .smalltHeading {
  margin-bottom: 20px;
  font-size: 18px;
}

.setting-hide-delete {
  margin-bottom: 120px;
}

.hideDeleteProfile {
  margin-bottom: 40px;
  margin-top: 60px;
}

.hideDeleteProfile img {
  cursor: pointer;
  transform: rotate(180deg);
  margin-bottom: 30px;
  width: 30px;
}

.commanheading {
  font-size: 30px;
}

.group-5 label .hideRadio {
  margin-right: 20px;
  margin-bottom: 0px;
  accent-color: #dd2477;
  font-size: 38px;
  height: 15px;
  width: 30px;
  cursor: pointer;
}

.group-5 label {
  margin-bottom: 12px;
}

.hidedeletepage .ReactModal__Overlay {
  background-color: #dd2477 !important;
}

.hidedeletepage {
  margin-bottom: 40px;
}

.blockedProfile img {
  transform: rotate(180deg);
}

.blockedProfile .headingPage {
  font-family: "Quiche Display Regular", "Montserrat";
  background: -webkit-linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
}

.blockedProfile {
  margin: 50px 0px;
  gap: 30px;
  display: grid;
}
.MatchesPage .blocked_profiles {
  min-height: calc(100vh - 268px);
}
.ReportUserPage .group-4 {
  margin-top: 50px;
}

.ReportUserPage .text-2 .smalltHeading {
  color: #dd2477;
  font-weight: 500;
  font-size: 21px;
  margin-bottom: 5px;
}

.textAreaBox textarea {
  border-radius: 10px;
  padding: 30px 0px 0px 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ReportUserPage .text-2 p,
.ReportUserPage .text-2 .button {
  margin-left: 0px;
}

.ReportUserPage .group-5 label {
  margin-bottom: 12px;
  cursor: pointer;
}
