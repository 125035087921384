@media (max-width: 768px) {
  .login-logo {
    width: 100%;
  }

  .login-logo a {
    width: 100%;
    text-align: center;
    display: block;
  }

  /*--- without login Home Page ---*/

  .ftrSclLinks ul {
    justify-content: center;
    padding-left: 0px;
  }

  .ftrSclLinks+p {
    text-align: center;
  }

  .mainHeader header {
    padding: 25px 10px;
  }

  .navbar-toggler {
    border: none;
  }

  .navbar-toggler-icon {
    background-color: transparent;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .LandingPageSection .mainHeader .nav-item a {
    /* line-height: 40px; */
    height: max-content;
    padding: 10px 20px;
    color: #fff;
  }

  .emoji {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .profileimg {
    width: 80%;
    object-fit: contain;
    aspect-ratio: 1/1;
    margin: auto;
  }

  .happystoriePage .profile img {
    width: 100%;
  }

  .subpriction_Video_Image {
    aspect-ratio: 1/1;
    text-align: center;
  }

  .subpriction_Video_Image video {
    width: 70%;
    height: auto;
    border-radius: 0px;
    margin: auto;
  }

  .videoRecorderBox .prsl-detail-heading {
    font-size: 18px;
  }

  .redirectBar {
    padding: 20px 15px;
  }

  .videoRecorderBox video {
    width: 100%;
  }

  .MatchesPage>div:not(.headerFixedonScroll) {
    padding: 10px 10px;
  }

  .requiredDocsPage .custom-file-input[name="govtcert"]>.custom-file-label img {
    background-color: red !important;
    margin-top: 15px !important;
  }

  .requiredDocsPage .terms {
    margin-top: 0px;
    padding: 15px 5px 5px 5px;
  }

  .login_form .error-msg {
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }

  .error-msg {
    display: block;
  }

  .requiredDocsPage #msform .divider {
    margin: 30px 0px 20px 0px;
  }

  .requiredDocsPage #msform .left-btn {
    font-size: 16px;
  }

  /*----- Registration ------*/

  .PersonalFormRegistraion .col-md-6 {
    padding: 0px 20px;
  }

  .PersonalFormRegistraion .btn-flex {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .PersonalFormRegistraion #msform .divider {
    margin: 20px 0px 30px 0px;
  }

  /*---- Placeholder ----*/

  input::placeholder,
  select,
  input[type="date"] {
    font-size: 14px !important;
    color: #000 !important;
  }
  .dob-input #myDateLabel {
    display: block;
  }
  .dob-input #myDateLabel {
    position: absolute;
    top: 15px;
    left: 15px;
    pointer-events: none;
    color: #000;
    border-radius: 28px;
    font-size: 16px;
    font-family: "Montserrat";
    display: block;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
    font-weight: 400;
    line-height: 19.5px;
    width: 100%;
}
  /*-- AdditionalForm ->> photo Perview --*/

  .CustomModal .image-container {
    grid-template-columns: 1fr;
  }

  .CustomModal .remove-button {
    top: -11px;
    right: -7px;
    padding: 0px 5px;
    font-size: 12px;
  }

  .CustomModal .close-button {
    font-size: 12px;
    width: 70px;
    height: 24px;
  }

  /*-- AdditionalForm ->> video perview -*/

  .videoinerbox {
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .videoRecorderBox video {
    width: 100% !important;
  }

  .videoPerviewModal center>div {
    width: 100% !important;
    height: 100% !important;
  }

  .btnbox {
    display: flex !important;
    justify-content: center;
  }

  .videoPerviewModal .videoRecorderFooter button {
    margin: 0px;
  }

  /*-- common --*/

  .commonBtn {
    margin-right: 0px;
  }

  .prsl-detail textarea {
    width: 100%;
  }

  /*---  address detail --*/

  .upldicn label img {
    width: 18px;
    margin-bottom: 10px;
  }

  form input,
  form #file-input-label {
    padding: 12px 15px;
  }

  .addressForm+.container .detail-titleTwo {
    gap: 20px;
    flex-wrap: wrap;
  }

  /*-- registration --*/

  .login_form select.dropdown-menu {
    font-size: 12px;
  }

  .EducationWorkfrom .commonBtn {
    margin-right: 0px;
  }

  .mln50 {
    margin-left: 0px;
  }

  /* #progressbar {
    display: none;
  } */

  .registrainHeader .container .row .col-md-4 {
    padding: 0px 20px;
  }

  .EducationWorkfrom+.container {
    padding: 0px 30px;
  }

  /*--- numerology-details --*/

  .insideAccordian .grid3 {
    margin: 20px 0px 0px;
  }

  .NumerologyDetails .grid1 {
    margin: 0px 0px;
  }

  /*-- Numerologoy-match --*/

  .MatchesPage .NumerologyDetailsPage .commonBtn {
    margin-right: 0px;
  }

  .NumerologyDetailsPage input {
    margin-bottom: 10px !important;
  }

  .NumerologyDetailsPage .innerBox {
    margin-right: 0px;
    padding: 0px 15px;
  }

  .NumerologyDetailsPage .innerBox2 {
    margin-left: 0px;
    padding: 0px 15px;
  }

  .NumerologyDetailsPage .prsl-detail {
    margin-bottom: 10px;
  }

  /*--- report User --*/

  .textAreaBox textarea {
    width: 100%;
  }

  .hideDeleteProfile {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .ReportUserPage .group-2 {
    width: 100%;
  }

  .ReportUserPage .group-5 label {
    font-size: 15px;
  }

  .group-5 label .hideRadio {
    margin-right: 0px;
    cursor: pointer;
  }

  .box2 {
    margin-top: 20px;
    padding-top: 20px;
  }

  /*--  --*/

  .fullProfilePage .acdinNameBox img {
    width: 28px;
    height: 28px;
  }

  .grid3>div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .MyprofileForm label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 6px;
    margin-top: 10px;
  }

  /*--profile/kundliMatchPage--*/

  .fullProfilePage .MyprofileForm {
    padding: 10px 15px;
  }

  .kundliMatchPage .py25 {
    padding: 40px 0px 0px 0px;
  }

  .kundliMatchPage .commonBtn {
    margin-right: 0px;
  }

  .kundliMatchPage .prsl-detail {
    margin-bottom: 0px;
    padding: 20px 0px 0px 0px;
  }

  .kundliMatchPage .btn-flex {
    margin-bottom: 70px;
    margin-top: 10px;
  }

  .kundliMatchPage .innerBox2 {
    margin-left: 0px;
  }

  .NumerologyDetailsPage .dropdown-menu {
    margin-bottom: 10px !important;
  }

  .innerBox::before {
    display: none;
  }

  .innerBox {
    margin-right: 0px;
  }

  #msform .dflex {
    flex-wrap: wrap;
  }

  /*-- kundaliDetails --*/

  .kundaliDetails .MyprofileForm {
    padding: 0px !important;
  }

  .kundaliDetails .insideAccordian .accordion-button:not(.collapsed) {
    padding: 5px 0px 5px 0px;
  }

  .kundaliDetails .insideAccordian {
    padding: 20px 20px;
  }

  /*-- NumerologyDetails --*/

  .NumerologyDetails .MyprofileForm label {
    margin-top: 10px;
  }

  .NumerologyDetails .MyprofileForm {
    padding: 10px 20px;
  }

  .NumerologyDetails .profileImgInrBox {
    aspect-ratio: auto;
  }

  .NumerologyDetails .back-btn {
    margin-bottom: 30px;
  }

  /*--- requiredDocsPage ---*/

  .requiredDocsPage .btn-flex {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .requiredDocsPage .commonBtn {
    margin-right: 0;
  }

  .requiredDocsPage .upldicn label img {
    width: 14px;
  }

  .requiredDocsPage .terms ul {
    padding-left: 15px;
  }

  .requiredDocsPage .terms {
    width: 100%;
  }

  .requiredDocsPage .terms p {
    font-size: 18px;
    line-height: 26px;
  }

  /*--- profile/chat --*/

  .list {
    margin-bottom: 20px;
  }

  .chatList {
    height: fit-content;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  /*-- Change Password and login,logout ect. sign In/Out screen --*/

  .registrationFree {
    margin-bottom: 15px;
  }

  .login-regstr span.color {
    font-size: 14px;
  }

  .login-regstr p {
    font-size: 14px;
  }

  .forgot-password-form h1 {
    margin-top: 30px;
  }

  .forgot-password-form p {
    margin-bottom: 45px;
  }

  .forgot-password {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 0px 5px 0;
  }

  .login-form {
    padding: 0px 0px 0px 0;
  }

  button.loginbtn {
    border-radius: 100px;
    font-size: 16px;
    width: 100%;
  }

  .login-logo {
    margin-bottom: 0;
    text-align: center;
    width: 100%;
  }

  .login-logo img {
    width: 70%;
  }

  .innr_frm {
    padding: 0 20px 0px;
    text-align: center;
  }

  .login-form h1 {
    font-size: 25px;
    margin: 15px 0px;
  }

  .login_form {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 0px 5px 0;
  }

  .login_left_sec {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    object-fit: contain;
  }

  .login_left_sec img {
    object-fit: cover;
  }

  .login_right_sec {
    width: 100%;
    padding: 30px 15px;
  }

  /*-- Hide/Delete Profile --*/

  .setting-hide-delete .dflex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .setting-hide-delete .box2::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    content: " ";
    background: red;
    height: 1px;
    width: calc(100% + 0%);
  }

  .setting-hide-delete {
    margin-bottom: 40px;
    padding: 0px 15px;
  }

  .hidedeletepage .col-lg-12 {
    padding: 0px 30px;
  }

  .hidedeletepage .col-md-3 .text-3 {
    margin-bottom: 15px;
  }

  /*-- profile/requests --*/

  .MatchesPage .RequestPage .profileCardFtr {
    display: flex;
    border-top: 1px solid #d9d9d9;
    right: 0px;
    left: 0px;
    gap: 15px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    align-items: center;
    height: 40px;
    justify-content: center;
  }

  .RequestPage .profileCardFtr p {
    font-size: 10px;
    margin-bottom: 0px;
  }

  .RequestPage .profileCardFtr img,
  .likeAge img {
    width: 17px;
  }

  .rsa ul {
    margin: 0px 0px 0px 0px;
  }

  /*-- profile/settings --*/

  .mysubscription {
    margin-bottom: 140px;
    padding: 0px 15px;
  }

  .mysubscription .notificationCard {
    height: fit-content;
    max-height: 250px;
    overflow-y: scroll;
    padding-bottom: 30px;
  }

  .settingPageBox {
    margin: 20px 0px;
  }

  .ctaboxmain {
    padding: 80px 20px 75px 20px;
  }

  .settingLabel {
    margin: 20px 0px;
    font-size: 18px;
  }

  .settingPageBox .commanheading {
    color: #f2663b;
    font-size: 25px;
  }

  .settingPageBox {
    margin: 20px 0px;
  }

  .mysubscription .settingForm .inputBox {
    align-items: center;
  }

  .mysubscription .settingForm .inputBox .settingLabel {
    margin: 26px 0;
    line-height: normal;
  }

  .MatchesPage .blocked_profiles {
    min-height: 100vh;
  }

  .MatchesPage .blocked_profiles+footer {
    margin-top: 150px !important;
  }

  .MatchesPage .blocked_profiles .blockedProfile {
    margin: 0;
  }

  .ctaboxmain {
    padding: 80px 20px 75px 20px;
  }

  /*-- profile/myprofile --*/

  .uploadBox input[type="file"]::file-selector-button {
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: transparent;
    transition: 1s;
  }

  .myProfileUplodimgModal {
    margin: 0;
  }

  .myProfileModal {
    margin: 0px;
    max-width: initial;
    min-width: initial;
  }

  .myProfileModal .storesImagesContainer {
    width: 45%;
    margin: 5px;
  }

  .goExclusive {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .acdinName {
    font-size: 14px;
  }

  .acdinNameBox p {
    font-size: 14px;
  }

  .MyprofileForm textarea {
    margin-bottom: 20px;
    width: 100%;
    margin-top: 0px;
  }

  .grid3 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
  }

  .accordion-header {
    padding-right: 10px;
    padding-left: 10px;
  }

  .accordion-body {
    padding-right: 0px;
    padding-left: 0px;
  }

  .contentNumber span {
    padding: 5px 15px;
    font-size: 11px;
  }

  .addphotos {
    background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
    padding: 7px 6px;
  }

  .addphotos .dflex {
    gap: 5px;
    font-size: 10px;
  }

  .addphotos img {
    width: 15px;
  }

  .nameNAge {
    color: #fff;
    font-size: 17px;
  }

  .userAge {
    margin: 0px;
  }

  .nameNAge .userName {
    font-size: 22px;
  }

  .ProfileOverlyDetail {
    padding: 10px;
  }

  /*-- profile/matches  --*/

  .profileImgBox .accordion {
    margin-top: 0px;
  }

  .myProfileModal .swiper-wrapper {
    height: 340px;
    overflow: hidden;
  }

  .profileImgInrBox {
    max-height: 340px;
    border-radius: 20px;
    overflow: hidden;
    aspect-ratio: 1/1;
  }

  .myProfileModal .swiper-slide-active .profileImgInrBox {
    height: 340px;
    overflow: hidden;
  }

  .mrgn0 {
    margin: 0px;
  }

  .myProfileModal {
    top: 11%;
  }

  .MatchesPage .profileCardFtr {
    display: flex;
    position: absolute;
    bottom: -10px;
    border-top: 1px solid #d9d9d9;
    right: 10px;
    left: 10px;
    justify-content: center;
    gap: 10px;
    padding-right: 0;
    padding-left: 0;
    padding-top: 5px;
  }

  .MatchesPage .profileCardFtr p {
    margin-bottom: 5px;
  }

  .profileCardFtr p {
    font-size: 12px;
  }

  .profileDetail {
    padding: 25px 15px;
  }

  .profileImage {
    min-width: 100%;
  }

  .profileCard {
    max-height: max-content;
    flex-wrap: wrap;
    padding-bottom: 10px;
  }

  .Tabnames ul li {
    padding: 5px 8px;
    font-size: 11px;
  }

  .Tabnames ul {
    gap: 5px;
    margin: 10px 0px;
    flex-wrap: wrap;
    justify-content: center;
  }

  /*-- payment page --*/

  .MatchesPage .headerCompo .nav-icon .icon svg {
    color: #ec407a;
  }

  .MatchesPage .headerCompo .nav-item .active {
    color: #fff;
  }

  .MatchesPage .backarrowbar {
    width: 90%;
  }

  .paymentPage .innerBox {
    width: 100%;
  }

  .paymentPage .button {
    margin-top: 30px;
  }

  /**---- happy-stories ---*/

  .happystoriePage .SubscriptionTopHeader.py70 {
    padding: 30px 0px !important;
    margin-bottom: 10px;
  }

  .contentBox h2 {
    font-size: 38px;
    font-weight: 600;
  }

  .profile {
    display: block;
  }

  .profile img {
    width: 100%;
  }

  .swiper-button-prev img {
    margin-top: -25px;
  }

  .arrobxline img {
    margin-top: 0px;
  }

  .happystoriePage .profile img {
    width: 100%;
  }

  .happystoriePage .headerCompo .nav-links {
    color: #ffffff;
  }

  .happystoriePage .headerCompo .nav-icon .icon svg {
    color: #ec407a;
  }

  .happystoriePage .py70 {
    padding: 20px 0px !important;
  }

  .profile {
    display: initial;
  }

  .SubscriptionTopHeader .commanheading {
    font-size: 32px;
  }

  .contentBox h2 {
    font-size: 28px;
    font-weight: 600;
  }

  .arrobxline button {
    padding: 20px 15px;
  }

  .swiper-button-prev img {
    margin-top: -15px;
    margin-left: -10px;
  }

  /*-- main Header --*/

  .klicksPageSection .navbar-nav .nav-item:nth-child(2) a {
    background: transparent;
    color: #ec407a;
  }

  .AboutUsPage .navbar-nav .nav-item:nth-child(1) a {
    background: transparent;
    color: #ec407a;
  }

  .mobileHidden {
    display: none;
  }

  .mainHeader .show {
    width: 100%;
    justify-content: center;
  }

  .mainHeader .nav-item {
    margin-bottom: 20px;
  }

  .mainHeader .nav-item a {
    padding: 10px 20px 10px 20px !important;
    color: #fff;
  }

  .mainHeader nav .navbar-toggler {
    padding: 0px;
    margin: 0px;
    border: none !important;
  }

  .mainHeader .navbar-toggler-icon {
    background-color: transparent;
  }

  .mainHeader .navbar-brand img {
    width: 85%;
  }

  .mainHeader .nav-icon .icon svg {
    color: #fff;
  }

  .mainHeader.nav-logo img {
    width: 80%;
  }

  .mainHeader .nav-icon .icon svg {
    color: #ec407a;
  }

  .mainHeader .nav-links {
    color: #fff;
  }

  .mainHeader .nav-item .active {
    background: none;
    color: #fff;
  }

  .mainHeader .nav-icon .icon svg {
    color: #dd2477;
  }

  .mainHeader .nav-links {
    color: #ffffff;
    padding: 15px 1.5rem;
  }

  .mainHeader .nav-links {
    padding: 10px 0px 16px 0px;
  }

  .mainHeader .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    position: sticky;
    top: 0;
    z-index: 20;
    padding: 0px 0px;
  }

  .mainHeader .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 85%;
    left: 0px;
    display: none;
    transition: all 0.5s ease;
    padding-bottom: 10px;
    padding-top: 20px;
  }

  .mainHeader .nav-menu.active {
    background: linear-gradient(45deg, #dd2477, #f36e36);
    box-shadow: 3px 9px 20px #5757577a;
    padding-bottom: 10px;
    border-radius: 10px;
    top: 85%;
    left: 0px;
    display: block;
    transition: all 0.5s ease;
    z-index: 1;
    padding-top: 20px;
  }

  .mainHeader .nav-icon .icon svg {
    color: #fff;
  }

  .mainHeader .nav-logo img {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mainHeader .nav-logo {
    width: 50%;
    margin-top: 0%;
  }

  .mainHeader .nav-container {
    justify-content: left;
  }

  .mainHeader header {
    padding: 25px 10px;
  }

  .mainHeader .scrolled .navbar {
    padding: 15px 10px;
  }

  /*--header-component ---*/

  .headerFixedonScroll .InnerDetailBlogPage .headerCompo .nav-icon .icon svg {
    color: #fff;
  }

  .InnerDetailBlogPage .headerCompo .nav-logo img {
    width: 80%;
  }

  .InnerDetailBlogPage .headerCompo .nav-icon .icon svg {
    color: #ec407a;
  }

  .headerCompo .nav-links {
    color: #fff;
  }

  .headerCompo .nav-item .active {
    background: none;
    color: #fff;
  }

  .SubscriptionPage .headerCompo .nav-icon .icon svg {
    color: #dd2477;
  }

  .SubscriptionPage .headerCompo .nav-links {
    color: #ffffff;
    padding: 15px 1.5rem;
  }

  .LandingPageSection .headerFixedonScroll .headerCompo .nav-links {
    padding: 10px 0px 16px 0px;
  }

  .headerCompo .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    position: sticky;
    top: 0;
    z-index: 20;
    padding: 0px 0px;
  }

  .headerCompo .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 85%;
    left: 0px;
    display: none;
    transition: all 0.5s ease;
    padding-bottom: 10px;
    padding-top: 20px;
  }

  .headerCompo .nav-menu.active {
    background: linear-gradient(45deg, #dd2477, #f36e36);
    box-shadow: 3px 9px 20px #5757577a;
    padding-bottom: 10px;
    border-radius: 10px;
    top: 85%;
    left: 0px;
    display: block;
    transition: all 0.5s ease;
    z-index: 1;
    padding-top: 20px;
  }

  .headerCompo .nav-icon .icon svg {
    color: #fff;
  }

  .headerCompo .nav-logo img {
    width: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .headerCompo .nav-logo {
    width: 50%;
    margin-top: 0%;
  }

  .headerCompo .nav-container {
    justify-content: left;
  }

  /*-- Home page ---*/

  .masthead {
    height: 600px;
  }

  .masthead__p p {
    font-size: 24px;
    padding: 0px 10px;
    line-height: 40px;
    margin-bottom: 0px;
  }

  .masthead__p {
    bottom: 20%;
  }

  .optionTextBarBox {
    padding: 10px 0px 20px 10px;
  }

  .optionTextIcnBar a img {
    width: 60px;
  }

  .plans_otrBox {
    display: block;
  }

  .sbscrptninrBox p {
    padding-right: 0px;
    font-size: 35px;
  }

  .commanheading {
    font-size: 22px;
  }

  .ctaboxmain {
    padding: 40px 20px;
  }

  .happystoriePage .happy_stories {
    /* min-height: calc(100vh - 143px); */
    min-height: 100vh;
  }

  .happystoriePage .ctabox .ctaboxmain {
    padding: 70px 20px;
  }

  .happystoriePage footer {
    margin-top: 0 !important;
  }

  .ctaboxtext h5 {
    font-size: 20px;
  }

  .imgtextsctn {
    padding: 30px 0px 30px 0px;
  }

  .serviceBox {
    padding: 30px 0px;
  }

  .plans_otrBox .plansBx:nth-child(1) {
    margin-bottom: 20px;
  }

  section.offerbar ul {
    text-align: center;
    display: block;
    padding: 15px 0px;
  }

  section.offerbar ul li {
    margin-bottom: 5px;
  }

  section.offerbar {
    margin: 30px 0px;
  }

  .securityReasonmainbx {
    padding: 40px 0px;
  }

  .securityReasoninrbx ul {
    padding: 0;
    width: 100%;
  }

  .securityReasoninrbx ul li p {
    height: fit-content;
  }

  .side_panel_head_profile_img {
    width: 100%;
  }

  .ftrinrbx {
    display: block;
    padding: 10px 0px;
    font-family: "Montserrat";
    text-align: center;
  }

  .navbar-brand {
    width: 70%;
  }

  .navbar-collapse {
    background: #4d122e;
    display: flex;
    text-align: center;
    border-radius: 10px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    justify-content: center;
  }

  .navbar-toggler-icon {
    background-color: #f1653b;
    border-radius: 4px;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .serviceIcnBoxtitle p {
    font-size: 16px;
  }

  .navbar-brand img {
    width: 15%;
  }

  .serviceIcnBoxicn {
    margin-bottom: 5px;
  }

  .serviceIcnBox {
    margin-bottom: 40px;
  }

  .optionTextBarBox {
    gap: 20px;
    padding: 20px 15px;
  }

  .small_heading {
    font-size: 15px;
    line-height: 20px;
    margin-top: 10px;
  }

  .optionTextBarBox .mr90 {
    margin-right: 0px;
  }

  .borderNone:has(.optionTextBar) p {
    padding-right: 0px;
  }

  .ctabox {
    margin: 0px 0px;
  }

  .imgtextsctnmain .alignItemCenter {
    flex-direction: column-reverse;
    display: flex;
    padding: 0px 15px;
  }

  .imgtextsctnmain>.container {
    padding-bottom: 0px;
  }

  .sbscrptnMainBox .alignItemCenter {
    flex-direction: row;
  }

  .sbscrptninrBox p {
    padding-right: 0px;
    font-size: 8.2vw;
    line-height: 35px;
    margin-bottom: 30px;
  }

  .sbscrptnMainBox {
    padding: 0px 15px;
  }

  .plansBx {
    padding: 15px 20px;
  }

  .plansBx ul li p {
    margin-bottom: 0px;
    font-size: 14px;
  }

  .sbscrptnMainBox .alignItemCenter {
    flex-direction: row;
    overflow-x: scroll;
  }

  .sbscrptnMainBox .alignItemCenter::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    background-color: transparent;
  }

  .sbscrptnMainBox .alignItemCenter:-webkit-scrollbar-track {
    background-color: transparent;
  }

  .plans_otrBox {
    display: flex;
    gap: 10px;
    width: 530px;
    justify-content: flex-start;
    overflow: hidden;
    margin-top: 40%;
  }

  .plans_otrBox .plansBx {
    width: 250px;
    padding: 15px;
  }

  .sbscrptnMainBox .sbscrptninrBox .commanheading {
    position: absolute;
    left: 30px;
    width: 75%;
  }

  .ftrSclLinks+p {
    text-align: center;
  }

  .ftrSclLinks ul {
    padding: 0px;
    gap: 15px;
  }

  .ftrinrbx {
    grid-template-columns: auto;
  }

  .copyright {
    font-size: 13px;
  }

  .ftrSclLinks li img {
    width: 25px;
  }

  .ftrSclLinks+p {
    font-size: 16px;
  }

  section.offerbar ul li {
    padding-right: 60px;
  }

  /*------About Us Page ------*/

  .securityReasoninrbx {
    padding: 0px 10px;
  }

  .securityReasoninrbx ul li {
    font-size: 16px;
    line-height: 21px;
  }

  .sprHeading {
    margin-top: 40px;
    margin-bottom: 0px;
  }

  .AboutUsPage .headerstyle {
    margin: 0;
    padding: 0px 0px;
  }

  .AboutUsPage .sprHeading .alignItemCenter .col-md-12 {
    padding: 0px 15px;
  }

  .AboutUsPage .sprHeading .col-md-12 {
    padding: 0px 25px;
  }

  .headerstylefLi {
    margin-bottom: 15px;
  }

  .sprHeading+.col-md-12 {
    padding: 0px;
  }

  .imgtextbxtxt {
    margin: 30px 0px 30px 0px;
    padding: 0;
    position: relative;
  }

  .imgtextsctn .imgtextsctnmain .imgtextbxtxt::after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: -30px;
    border-bottom: 1px solid #ef6241;
  }

  .sclicnbx {
    margin-top: 15px;
  }

  .sclicnbx a {
    text-decoration: none;
    margin: 0px 5px;
  }

  .sclicnbx img {
    width: 25px;
  }

  .founderCeo .imgtextbximg {
    margin-top: 40px;
  }

  .founderCeo {
    margin-bottom: 0px;
  }

  .headerstyle {
    display: block;
  }

  .headerstyle.rowReverse li p {
    padding: 0px 0px 0px 0px;
  }

  .headerstyleSLi {
    min-width: 88%;
    width: 100%;
    margin-left: 0px;
  }

  .AboutUsPage .commandescription {
    font-size: 14px;
    padding: 10px 15px 0px 15px;
  }

  .AboutUsPage .imgtextbxtxt {
    margin: 10px 0px 0px 0px;
  }

  .AboutUsPage .imgtextbximg {
    padding: 0px 15px;
  }

  .ftrSclLinks+p {
    font-size: 12px;
  }

  .copyright {
    font-size: 11px;
  }

  .ftrSclLinks ul {
    margin-bottom: 15px;
  }

  .copyright {
    margin-bottom: 8px;
  }

  .ftrSclLinks ul {
    margin-bottom: 10px;
    justify-content: center;
  }

  /*---- Subscription Page ----*/

  .parentBoxPlanBx {
    width: 100vw;
    overflow-x: scroll;
  }

  .parentBoxPlanBx::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    background-color: transparent;
  }

  .parentBoxPlanBx::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .SubscriptionSection .plans_otrBox .plansBx {
    min-width: 320px;
    margin-left: 10px;
    margin-right: 10px;
  }

  footer {
    margin-top: 50px !important;
  }

  .SubscriptionSection .plans_otrBox {
    gap: 0px !important;
    display: flex !important;
    width: 700px !important;
    overflow-x: scroll !important;
    padding-top: 0px !important;
    justify-content: initial;
    margin-top: 0%;
  }

  .SubscriptionSection .plansBx ul li:last-child {
    margin-bottom: 15px;
  }

  .SubscriptionPage .py70 {
    padding: 20px 0px !important;
    margin: 10px 0px;
  }

  .SubscriptionPage .ctaboxmain .ctaboxtext img {
    width: 20%;
  }

  .SubscriptionText .mt40 {
    margin-top: 50px;
  }

  .SubscriptionText .fontMontserrat {
    font-size: 14px;
  }

  /*--- Klicks Page ---*/

  .klicksharemodal .react-responsive-modal-modal {
    margin: 0rem;
  }

  .klicksPageSection .pausePlayButton {
    position: absolute;
    top: 45%;
    left: 60%;
    transform: translate(-150%, -50%);
    z-index: 1;
  }

  .klicksPageSection .headerCompo .nav-icon .icon svg {
    color: #dd2477;
  }

  .shareBox form input {
    margin-bottom: 15px;
    font-size: 12.9px;
  }

  .shareBox {
    width: 230px;
  }

  .react-responsive-modal-modal {
    max-width: 280px;
    min-width: 280px;
  }

  .shareBox form {
    display: block;
    margin-top: 5px;
  }

  .video-cards {
    justify-content: space-evenly;
    width: calc(100vw + 0vw);
  }

  .videoOptionPanel {
    padding-top: 64%;
    margin-left: 10px;
    position: absolute;
    right: 20px;
    bottom: -10px;
  }

  .video-player {
    object-fit: cover !important;
    border-radius: 20px !important;
    position: relative;
    height: 580px;
    width: inherit;
    margin: -40px 0px;
    aspect-ratio: 11/20;
  }

  .videoOptionPanel img {
    width: 20px !important;
  }

  .video-wrapper-content {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
  }

  .videoDescription span {
    font-size: 10px;
    margin-bottom: 10px;
  }

  .videoDescription span+span span {
    padding: 5px 12px;
    border-radius: 10px;
    font-size: 10px;
    line-height: 28px;
  }

  .klicksPageSection {
    overflow: hidden;
  }

  .video-wrapper {
    margin-top: 15%;
    position: relative;
    border-radius: 10px;
  }

  .klicksPageSection #video-player .video-wrapper {
    margin: 0;
  }
  
  .klicksPageSection #video-player .video-wrapper video {
    width: 100%;
    height: 480px;
    margin: 0;
    aspect-ratio: 1;
  }

  .klicksPageSection #video-player .video-wrapper .arrowNavigationBar {
    bottom: -65px;
    top: auto;
    width: 100%;
    left: 0;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 100px;
  }

  .klicksPageSection #video-player .video-wrapper .arrowNavigationBar .firstArrow {
    transform: rotate(180deg) !important;
  }

  .klicksPageSection #video-player .video-wrapper .arrowNavigationBar .SecondArrow {
    transform: rotate(0deg) !important;
  }

  .klicksPageSection #video-player .video-wrapper .videoOptionPanel {
    padding: 0;
    display: flex;
    align-items: center;
    bottom: -30px;
    right: 0;
    left: 0;
    margin: 0;
    justify-content: center;
    gap: 30px;
  }

  .klicksPageSection #video-player .video-wrapper .videoOptionPanel .klickclick {
    margin: 0;
  }

  .klicksPageSection #video-player {
    padding: 30px 12px 100px;
  }

  /*---- Blog Page -----*/

  .blogDtlPgHdngBox {
    margin-top: 40px;
    padding: 15px;
  }

  .blogDtlPgHdngBox .headingPage {
    font-size: 20px;
    margin-top: 30px;
    width: 100%;
  }

  .blogDtlPara {
    padding: 0px 15px;
    margin-bottom: 50px;
    margin-top: 0px;
  }

  .blogPgHdng {
    margin-bottom: 20px;
  }

  .BlogCardImgBox {
    height: auto;
    overflow: hidden;
  }

  .paginationAlign {
    margin-top: 0px;
  }

  /*-- Contact Us page ---*/

  .contactPage .headerCompo .nav-icon .icon svg {
    color: #dd2477;
  }

  .contactPageSection .container .row:nth-child(1) .contactUsPreBox {
    padding: 20px 15px 5px 30px;
    border-radius: 15px;
    margin-bottom: 30px;
  }

  .contactUsPreBox #progressbar::before {
    width: 98%;
  }

  #progressbar::after {
    width: 100%;
  }

  .contactUsPreBox #progressbar li:nth-child(1) {
    margin-left: 75%;
  }

  .setheight {
    height: 20px;
    margin-top: 0px;
  }

  .contactUsPreBox {
    box-shadow: 0px 6px 12px 0px #90909045;
    padding: 20px 15px 5px 15px;
  }

  .contactMailIcntext {
    font-size: 2.5vh;
  }

  .contactPageSection .commanheading {
    color: #000;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
  }

  .contactPageSection .contactSubHeading {
    margin-top: 12px;
  }

  .contactPageSection .container .row:nth-child(2) {
    margin: 10px 0px 60px 0px;
  }

  .contactUsFormBox {
    margin: 30px 0px 0px 0px;
  }

  button.loginbtn {
    margin-top: 0px;
    margin-bottom: 0;
  }

  .fillgraidentandcenter {
    border-radius: 10px;
  }

  .fillgraidentandcenter img {
    width: 60px;
    margin-bottom: 15px;
  }

  .contactUsPreBox .contactUsFormBox {
    margin-top: 10px;
  }

  .contactform .loginbtn {
    padding: 5px 20px;
    font-size: 12px;
    margin-bottom: 15px;
  }
  #progressbar {
    padding: 0 20px;
}
#progressbar li.active {
  margin: 0;
}
#progressbar::before {
  width: 25%;
}
.EducationWorkfrom #progressbar li:nth-child(1) {
  margin-left: 0;
}
.EducationWorkfrom #progressbar::before {
  width: 35%;
}
.addressForm #progressbar li:nth-child(1) {
  margin-left: 0;
}
.family #progressbar li:nth-child(1) {
  left: 0;
}
.family #progressbar::before {
  width: 85%;
}
/* .additional #progressbar {
  justify-content: center;
} */
.additional #progressbar li:nth-child(1) {
  left: 0;
}
.progress-desktop#progressbar {
  display: none;
}
.progress-mobile#progressbar {
  display: flex !important;
}
.contactUsPreBox #progressbar {
  padding: 0;
  justify-content: end;
}
}

@media (min-width: 481px) and (max-width: 576px) {
  .profileimg {
    width: 80%;
    object-fit: contain;
    aspect-ratio: 1/1;
    margin: auto;
  }

  .happystoriePage .profile img {
    width: 100%;
  }

  .subpriction_Video_Image {
    aspect-ratio: 1/1;
    text-align: center;
  }

  .subpriction_Video_Image video {
    width: 70%;
    height: auto;
    border-radius: 0px;
    margin: auto;
  }

  .videoRecorderBox .prsl-detail-heading {
    font-size: 18px;
  }

  .redirectBar {
    padding: 20px 15px;
  }

  .videoRecorderBox video {
    width: 100%;
  }

  .NumerologyDetailsPage .btn-flex {
    margin-top: 0px;
  }

  .NumerologyDetailsPage .btn-flex .commonBtn {
    margin-right: 0px;
  }

  .NumerologyDetailsPage .prsl-detail {
    margin-bottom: 0px;
  }

  .NumerologyDetailsPage .innerBox,
  .innerBox2 {
    margin: 0px;
  }

  /* .NumerologyDetailsPage .innerBox {
  margin-right: 0px;
} */

  .NumerologyDetailsPage .innerBox2 {
    margin-left: 0px;
  }

  .textAreaBox textarea {
    width: 100%;
  }

  .setting-hide-delete .commanheading {
    font-size: 35px;
  }

  .show li.nav-item a {
    color: #fff;
  }

  /*-- registration --*/

  .PersonalFormRegistraion .col-md-6 {
    padding: 0px 20px;
  }

  .PersonalFormRegistraion .btn-flex .commonBtn {
    margin-right: 0px;
  }

  .PersonalFormRegistraion .btn-flex {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .PersonalFormRegistraion #msform .divider {
    margin: 20px 0px 20px 0px;
  }

  /*----*/

  .aboutus .imgtextsctnmain .sprHeading {
    margin-top: 50px;
    margin-bottom: 0px;
  }

  .profile {
    flex-wrap: wrap;
  }

  .profile img {
    margin: auto;
  }

  .arrobxline {
    bottom: 20px;
  }

  /*-- Hide/Delete Profile --*/

  .setting-hide-delete .dflex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .setting-hide-delete .box2::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    content: " ";
    background: red;
    height: 1px;
    width: calc(100% + 0%);
  }

  .setting-hide-delete {
    margin-bottom: 40px;
    padding: 0px 15px;
  }

  .hidedeletepage .col-lg-12 {
    padding: 0px 30px;
  }

  .hidedeletepage .col-md-3 .text-3 {
    margin-bottom: 15px;
  }

  /*-- profile/settings --*/

  .settingPageBox {
    margin: 20px 0px;
  }

  .ctaboxmain {
    padding: 80px 20px 75px 20px;
  }

  .settingLabel {
    margin: 20px 0px;
    font-size: 18px;
  }

  .settingPageBox .commanheading {
    color: #f2663b;
    font-size: 30px;
  }

  /*-- profile/myprofile --*/

  .goExclusive {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .acdinName {
    font-size: 14px;
  }

  .acdinNameBox p {
    font-size: 14px;
  }

  .MyprofileForm textarea {
    margin-bottom: 20px;
    width: 100%;
    margin-top: 0px;
  }

  .grid3 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
  }

  .accordion-header {
    padding-right: 10px;
    padding-left: 10px;
  }

  .accordion-body {
    padding-right: 0px;
    padding-left: 0px;
  }

  .contentNumber span {
    padding: 5px 15px;
    font-size: 11px;
  }

  .addphotos {
    background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
    padding: 7px 6px;
  }

  .addphotos .dflex {
    gap: 5px;
    font-size: 10px;
  }

  .addphotos img {
    width: 15px;
  }

  .nameNAge {
    color: #fff;
    font-size: 17px;
  }

  .userAge {
    margin: 0px;
  }

  .nameNAge .userName {
    font-size: 22px;
  }

  .ProfileOverlyDetail {
    padding: 10px;
  }

  /*-- profile/matches  --*/

  .profileDetail {
    padding: 25px 15px;
  }

  .profileImage {
    min-width: 100%;
  }

  .profileCard {
    max-height: max-content;
    flex-wrap: wrap;
  }

  .Tabnames ul li {
    padding: 10px 24px;
    font-size: 12px;
  }

  .Tabnames ul {
    gap: 5px;
    margin: 10px 0px;
    flex-wrap: wrap;
  }

  /*---- Subscription Page ----*/

  .parentBoxPlanBx {
    width: 100vw;
    overflow-x: scroll;
  }

  .parentBoxPlanBx::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    background-color: transparent;
  }

  .parentBoxPlanBx::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .SubscriptionSection .plans_otrBox .plansBx {
    min-width: 320px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .SubscriptionSection .plans_otrBox {
    gap: 0px !important;
    display: flex !important;
    width: 700px !important;
    overflow-x: scroll !important;
    padding-top: 30px !important;
    justify-content: initial;
  }

  .SubscriptionSection .plansBx ul li:last-child {
    margin-bottom: 15px;
  }

  .SubscriptionPage .py70 {
    padding: 20px 0px !important;
    margin: 10px 0px;
  }

  .SubscriptionPage .ctaboxmain .ctaboxtext img {
    width: 20%;
  }

  .SubscriptionText .mt40 {
    margin-top: 50px;
  }

  .SubscriptionText .fontMontserrat {
    font-size: 14px;
  }

  /*-- Contact Us page ---*/

  .contactPageSection .container .row:nth-child(1) .contactUsPreBox {
    padding: 20px 15px 5px 30px;
    border-radius: 15px;
    margin-bottom: 30px;
  }

  .contactUsPreBox #progressbar::before {
    width: 80%;
  }

  /* #progressbar::after {
    width: 80%;
  } */

  .contactUsPreBox #progressbar li:nth-child(1) {
    margin-left: 75%;
  }

  .setheight {
    height: 20px;
    margin-top: 0px;
  }

  .contactUsPreBox {
    box-shadow: 0px 6px 12px 0px #90909045;
    padding: 20px 15px 5px 15px;
    border-radius: 10px;
  }

  .contactMailIcntext {
    font-size: 2.5vh;
  }

  .contactPageSection .commanheading {
    color: #000;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
  }

  .contactPageSection .contactSubHeading {
    margin-top: 12px;
  }

  .contactPageSection .container .row:nth-child(2) {
    margin: 10px 0px 60px 0px;
  }

  .contactUsFormBox {
    margin: 30px 0px 0px 0px;
  }

  button.loginbtn {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .fillgraidentandcenter {
    border-radius: 10px;
  }

  .fillgraidentandcenter img {
    width: 60px;
    margin-bottom: 15px;
  }

  .contactUsPreBox .contactUsFormBox {
    margin-top: 10px;
  }

  .contactform .loginbtn {
    padding: 5px 20px;
    font-size: 12px;
    margin-bottom: 15px;
  }

  /*-- blog image --*/

  .blogDtlPgHdngBox .headingPage {
    font-size: 36px;
    margin-top: 40px;
    margin-bottom: 10px;
    width: 100%;
    line-height: 40px;
  }

  .blogDtlPara {
    padding: 0px 20px;
    margin-bottom: 50px;
  }

  /*--header-component ---*/

  .headerCompo .nav-item .active {
    background: none;
  }

  .SubscriptionPage .headerCompo .nav-icon .icon svg {
    color: #dd2477;
  }

  .SubscriptionPage .headerCompo .nav-links {
    color: #ffffff;
    padding: 15px 1.5rem;
  }

  .LandingPageSection .headerFixedonScroll .headerCompo .nav-links {
    padding: 10px 0px 16px 0px;
  }

  .headerCompo .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    position: sticky;
    top: 0;
    z-index: 20;
    padding: 0px 0px;
  }

  .headerCompo .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 85%;
    left: 0px;
    display: none;
    transition: all 0.5s ease;
    padding-bottom: 10px;
    padding-top: 20px;
  }

  .headerCompo .nav-menu.active {
    background: linear-gradient(45deg, #dd2477, #f36e36);
    box-shadow: 3px 9px 20px #5757577a;
    padding-bottom: 10px;
    border-radius: 10px;
    top: 85%;
    left: 0px;
    display: block;
    transition: all 0.5s ease;
    z-index: 1;
    padding-top: 20px;
  }

  .headerCompo .nav-icon .icon svg {
    color: #fff;
  }

  .headerCompo .nav-logo img {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .headerCompo .nav-logo {
    width: 50%;
    margin-top: 0%;
  }

  .headerCompo .nav-container {
    justify-content: left;
  }

  /*-- klick page --*/

  .shareBox form input {
    margin-bottom: 15px;
    font-size: 12.9px;
  }

  .shareBox {
    width: 230px;
  }

  .react-responsive-modal-modal {
    max-width: 280px;
    min-width: 280px;
  }

  .shareBox form {
    display: block;
    margin-top: 5px;
  }

  .video-cards {
    justify-content: space-evenly;
    width: calc(100vw + 0vw);
    margin-bottom: 200px;
  }

  .videoOptionPanel {
    padding-top: 64%;
    margin-left: 10px;
    position: absolute;
    right: 20px;
    bottom: -10px;
  }

  .video-player {
    object-fit: cover !important;
    border-radius: 20px !important;
    position: relative;
    height: 550px;
    width: inherit;
    margin: -40px 0px;
    aspect-ratio: 9/17;
  }

  .videoOptionPanel img {
    width: 20px !important;
  }

  .video-wrapper-content {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
  }

  .videoDescription span {
    font-size: 10px;
    margin-bottom: 10px;
  }

  .videoDescription span+span span {
    padding: 5px 12px;
    border-radius: 10px;
    font-size: 10px;
    line-height: 28px;
  }

  .klicksPageSection {
    overflow: hidden;
  }

  .video-wrapper {
    margin-top: 8%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }

  /*--header-component ---*/

  .headerCompo .nav-item .active {
    background: none;
    color: #fff;
  }

  .headerCompo .nav-links {
    color: #fff;
  }

  .SubscriptionPage .headerCompo .nav-icon .icon svg {
    color: #dd2477;
  }

  .SubscriptionPage .headerCompo .nav-links {
    color: #ffffff;
    padding: 15px 1.5rem;
  }

  .LandingPageSection .headerFixedonScroll .headerCompo .nav-links {
    padding: 10px 0px 16px 0px;
  }

  .headerCompo .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    position: sticky;
    top: 0;
    z-index: 20;
    padding: 0px 0px;
  }

  .headerCompo .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 85%;
    left: 0px;
    display: none;
    transition: all 0.5s ease;
    padding-bottom: 10px;
    padding-top: 20px;
  }

  .headerCompo .nav-menu.active {
    background: linear-gradient(45deg, #dd2477, #f36e36);
    box-shadow: 3px 9px 20px #5757577a;
    padding-bottom: 10px;
    border-radius: 10px;
    top: 85%;
    left: 0px;
    display: block;
    transition: all 0.5s ease;
    z-index: 1;
    padding-top: 20px;
  }

  .headerCompo .nav-icon .icon svg {
    color: #ec407a;
  }

  .headerCompo .nav-logo img {
    width: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .headerCompo .nav-container {
    justify-content: left;
  }

  /*-- header end ---*/

  .imgtextbxtxt {
    margin: 30px 0px 30px 0px;
    padding: 0;
    position: relative;
  }

  .imgtextsctn .imgtextsctnmain .imgtextbxtxt::after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: -40px;
    border-bottom: 1px solid #ef6241;
  }

  .ftrSclLinks+p {
    text-align: center;
  }

  .ftrSclLinks ul {
    justify-content: center;
    padding-left: 0px;
  }

  .sbscrptnBox {
    padding: 15px;
  }

  .optionTextBarBox .optionTextBar .small_heading {
    font-size: 16px;
  }

  .borderNone:has(.optionTextBar) p {
    padding-right: 30px;
  }

  .optionTextIcnBar a img {
    width: 80px;
  }

  .serviceIcnBoxicn img {
    width: 45px;
  }

  .serviceIcnBoxtitle p {
    font-size: 20px;
  }

  .serviceinrheader {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .serviceinrBox {
    padding: 0px 15px;
  }

  .navbar-brand {
    width: 70%;
  }

  .navbar-collapse {
    background: #4d122e;
    display: flex;
    text-align: end;
    border-radius: 10px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .navbar-toggler-icon {
    background-color: transparent;
    border-radius: 4px;
  }

  .navbar-toggler {
    border: none !important;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .masthead__p p {
    font-size: 28px;
    color: #fff;
  }

  .serviceIcnBox {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  .serviceBox {
    padding: 20px 0px;
  }

  .optionTextBarBox {
    padding: 50px 20px 50px 20px;
    margin: 0px;
  }

  .ctabox {
    margin: 0px 0px;
  }

  .optionTextBarBox .mr90 {
    margin-right: 30px;
  }

  .ctaboxmain {
    padding: 40px 20px;
  }

  .ctaboxtext h5 {
    font-size: 25px;
  }

  .imgtextsctn {
    padding: 40px 0px 0px 0px;
  }

  .imgtextbxtxt {
    margin-bottom: 50px;
    padding: 0px 15px;
  }

  .imgtextbximg {
    padding: 40px 15px 0px 15px;
  }

  .sclicnbx {
    margin-top: 20px;
  }

  .sclicnbx img {
    width: 30px;
  }

  .sbscrptninrBox p {
    padding-right: 20px;
    font-size: 8.2vw;
    line-height: 45px;
    margin-bottom: 20px;
  }

  .plans_otrBox .plansBx:nth-child(1) {
    display: block;
    margin-bottom: 20px;
  }

  section.offerbar ul {
    text-align: center;
    display: block;
  }

  section.offerbar ul li {
    margin-bottom: 5px;
  }

  .plans_otrBox {
    display: block;
    margin-top: 5%;
  }

  section.offerbar {
    margin: 30px 0px;
  }

  .securityReasoninrbx ul {
    width: 100%;
    padding: 0px;
  }

  .securityReasoninrbx ul li p {
    height: fit-content;
  }

  .securityReasonmainbx {
    padding: 40px 0px;
  }

  .ftrinrbx {
    display: block;
    text-align: center;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .subpriction_Video_Image {
    aspect-ratio: 1/1;
    text-align: center;
  }

  .subpriction_Video_Image video {
    width: 70%;
    height: auto;
    border-radius: 0px;
    margin: auto;
  }

  .contactPageSection .contactSubHeading {
    margin-top: 12px;
  }

  .contactPageSection .commanheading {
    color: #000;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
  }

  .contactPageSection .contactSubHeading {
    margin-top: 12px;
  }

  .aboutDetail ul li {
    margin-bottom: 4px;
  }

  .show li.nav-item a {
    color: #fff;
  }

  .contactPage .setheight {
    height: 20px;
    margin-top: 10px;
  }

  .contactPageSection .container .row:nth-child(2) {
    margin: 40px 0px;
  }

  .contactPageSection .commanheading {
    font-size: 28px;
    line-height: 40px;
  }

  .contactPageSection .container .row:nth-child(1) .contactUsPreBox {
    padding: 30px;
  }

  /*-- whitout login about page --*/

  .sprHeading {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .pl45 {
    padding-left: 0px;
    margin-top: 30px;
  }

  /*-- whitout login  home page --*/

  .sbscrptninrBox {
    margin: 45px 0px;
  }

  .ctabox {
    margin: 0px 0px;
  }

  .LandingPageSection~.imgtextsctn {
    padding: 40px 0px 0px 0px;
  }

  .imgtextsctnmain>.container {
    padding-bottom: 0px;
  }

  /*-- Hide/Delete Profile --*/

  .setting-hide-delete .dflex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .setting-hide-delete .box2::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    content: " ";
    background: red;
    height: 1px;
    width: calc(100% + 0%);
  }

  .setting-hide-delete {
    margin-bottom: 40px;
    padding: 0px 15px;
  }

  .hidedeletepage .col-lg-12 {
    padding: 0px 30px;
  }

  .hidedeletepage .col-md-3 .text-3 {
    margin-bottom: 15px;
  }

  /*-- profile/settings --*/

  .settingPageBox {
    margin: 20px 0px;
  }

  .ctaboxmain {
    padding: 80px 20px 75px 20px;
  }

  .settingLabel {
    margin: 20px 0px;
    font-size: 18px;
  }

  .settingPageBox .commanheading {
    color: #f2663b;
    font-size: 30px;
  }

  /*-- profile/request --*/
  .ctaboxmain {
    padding: 80px 20px 75px 20px;
  }

  /*-- profile/myprofile --*/

  .goExclusive {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .acdinName {
    font-size: 14px;
  }

  .acdinNameBox p {
    font-size: 14px;
  }

  .MyprofileForm textarea {
    margin-bottom: 20px;
    width: 100%;
    margin-top: 0px;
  }

  .grid3 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
  }

  .accordion-header {
    padding-right: 10px;
    padding-left: 10px;
  }

  .accordion-body {
    padding-right: 0px;
    padding-left: 0px;
  }

  .contentNumber span {
    padding: 5px 15px;
    font-size: 11px;
  }

  .addphotos {
    background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
    padding: 7px 6px;
  }

  .addphotos .dflex {
    gap: 5px;
    font-size: 10px;
  }

  .addphotos img {
    width: 15px;
  }

  .nameNAge {
    color: #fff;
    font-size: 17px;
  }

  .userAge {
    margin: 0px;
  }

  .nameNAge .userName {
    font-size: 22px;
  }

  .ProfileOverlyDetail {
    padding: 10px;
  }

  /*--header-component ---*/

  .headerCompo .nav-links {
    color: #fff;
  }

  .headerCompo .nav-item .active {
    color: #fff;
  }

  .MatchesPage .headerCompo .nav-item .active {
    background: none;
  }

  .MatchesPage .headerCompo .nav-icon .icon svg {
    color: #dd2477;
  }

  .SubscriptionPage .headerCompo .nav-links {
    color: #ffffff;
    padding: 15px 1.5rem;
  }

  .LandingPageSection .headerFixedonScroll .headerCompo .nav-links {
    padding: 10px 0px 16px 0px;
  }

  .headerCompo .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    position: sticky;
    top: 0;
    z-index: 20;
    padding: 0px 0px;
  }

  .headerCompo .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 85%;
    left: 0px;
    display: none;
    transition: all 0.5s ease;
    padding-bottom: 10px;
    padding-top: 20px;
  }

  .headerCompo .nav-menu.active {
    background: linear-gradient(45deg, #dd2477, #f36e36);
    box-shadow: 3px 9px 20px #5757577a;
    padding-bottom: 10px;
    border-radius: 10px;
    top: 85%;
    left: 0px;
    display: block;
    transition: all 0.5s ease;
    z-index: 1;
    padding-top: 20px;
  }

  .headerCompo .nav-icon .icon svg {
    color: #df2e6c;
  }

  .headerCompo .nav-logo img {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .headerCompo .nav-logo {
    width: 50%;
    margin-top: 0%;
  }

  .headerCompo .nav-container {
    justify-content: left;
  }

  /**----header end-----**/

  /*--- profile/matches --*/

  .profileCardFtr img {
    width: 18px;
  }

  .username {
    margin-bottom: 5px;
  }

  .profileCardFtr {
    bottom: 10px;
    border-top: none;
    gap: 30px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .profileCardFtr p {
    font-size: 12px;
    gap: 6px;
    margin-bottom: 0px;
  }

  .profileDetail {
    padding: 10px 15px;
  }

  .profileImage {
    min-width: 160px;
    max-width: 160px;
  }

  .Tabnames ul {
    display: flex;
    gap: 10px;
    margin: 10px 0px;
    flex-wrap: wrap;
  }

  .ftrSclLinks ul {
    justify-content: center;
  }

  .SubscriptionSection .plans_otrBox .plansBx {
    width: 50%;
  }

  .SubscriptionSection .plans_otrBox {
    gap: 10px;
    padding: 0px 10px;
  }

  .SubscriptionSection .plansBx {
    padding: 35px 15px 0px 15px;
  }

  .SubscriptionText .mt40 {
    margin-top: 50px;
    margin-bottom: 0;
  }

  .SubscriptionSection .offerbar {
    margin: 0px 0px !important;
  }

  .navbar-brand {
    width: 70%;
  }

  .navbar-collapse {
    background: #4d122e;
    display: flex;
    text-align: end;
    border-radius: 10px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .masthead__p p {
    font-size: 28px;
    color: #fff;
  }

  .serviceIcnBox {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  .serviceBox {
    padding: 20px 0px;
  }

  .optionTextBarBox {
    padding: 30px 20px 30px 20px;
  }

  .ctaboxmain {
    padding: 40px 20px;
  }

  .ctaboxtext h5 {
    font-size: 25px;
  }

  .imgtextsctn {
    padding: 40px 0px 60px 0px;
  }

  .imgtextbxtxt {
    margin-bottom: 50px;
  }

  .sbscrptninrBox p {
    padding-right: 0px;
  }

  .plans_otrBox .plansBx:nth-child(1) {
    display: block;
    margin-bottom: 20px;
  }

  section.offerbar ul {
    text-align: center;
    display: block;
  }

  section.offerbar ul li {
    margin-bottom: 5px;
  }

  .plans_otrBox {
    display: block;
  }

  section.offerbar {
    margin: 30px 0px;
  }

  .securityReasoninrbx ul {
    width: 100%;
    padding: 0px;
  }

  .securityReasoninrbx ul li p {
    height: fit-content;
  }

  .securityReasonmainbx {
    padding: 40px 0px;
  }

  .ftrinrbx {
    display: block;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 993px) {
  body .mysubscription .col-md-7 {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .prsl-detail textarea {
    width: 100%;
  }

  .videoRecorderBox .prsl-detail-heading {
    font-size: 18px;
  }

  .redirectBar {
    padding: 20px 15px;
  }

  .videoRecorderBox video {
    width: 90%;
  }

  .profileCardFtr {}

  .profileCardFtr p {
    font-size: 11px;
    gap: 5px;
  }

  .profileCardFtr {
    border-top: none;
    right: 50px;
    left: 25px;
    gap: 15px;
    padding-top: 15px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .profileImage {
    min-width: 170px;
    max-width: 170px;
  }

  .NumerologyDetailsPage .prsl-detail {
    margin-bottom: 0px;
  }

  .NumerologyDetailsPage .btn-flex .commonBtn {
    margin-right: 0px;
  }

  .NumerologyDetailsPage .btn-flex {
    margin-bottom: 50px;
    margin-top: 0px;
  }

  .NumerologyDetailsPage .prsl-detail-heading {
    font-size: 16px;
  }

  .NumerologyDetailsPage .innerBox,
  .NumerologyDetailsPage .innerBox2 {
    margin: 0px;
  }

  .MyprofileForm textarea {
    width: 100%;
  }

  .goExclusive .plansBx button {
    padding: 12px 10px;
  }

  .plansBx {
    padding: 20px 10px;
  }

  .aboutDetail ul li {
    margin-bottom: 2px;
  }

  .profileDetail {
    padding: 5px 15px;
  }

  .headerCompo .nav-logo img {
    width: 60% !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mainHeader header {
    padding: 25px 10px;
  }

  .side_panel_head_profile_img {
    width: 60%;
  }

  .scrolled nav {
    padding: 0px 0px !important;
  }

  /*-- Registration --*/

  .PersonalFormRegistraion .btn-flex .commonBtn {
    margin-right: 0px;
  }

  .PersonalFormRegistraion .btn-flex {
    margin-bottom: 0px;
    margin-top: 30px;
  }

  .PersonalFormRegistraion #msform .divider {
    margin: 30px 0px 30px 0px;
  }

  .requiredDocsPage .col-md-1,
  .PersonalFormRegistraion .col-md-1 {
    display: none;
  }

  .requiredDocsPage .col-md-11,
  .PersonalFormRegistraion .col-md-11 {
    width: 100% !important;
  }

  .requiredDocsPage .col-md-3,
  .PersonalFormRegistraion .col-md-3 {
    display: none;
  }

  .requiredDocsPage .col-md-6,
  .PersonalFormRegistraion .col-md-6 {
    width: 80% !important;
    margin: auto;
  }

  .requiredDocsPage .detail-title,
  .PersonalFormRegistraion .detail-title {
    margin-left: 0px;
  }

  .login-form {
    padding: 0px 0px 10px 0;
  }

  .login-logo {
    margin-bottom: calc(100% / 10);
  }

  .login-logo a {
    text-align: center;
    display: block;
  }

  .login-regstr {
    margin-bottom: 24px;
  }

  .login-logo img {
    width: 60%;
    margin-top: 20px;
  }

  .login-logo {
    width: 100%;
  }

  .login-form h1 {
    text-align: center;
  }

  .login_right_sec {
    width: 60%;
    padding: 30px 155px;
    margin: auto;
  }

  .login_form {
    flex-wrap: wrap;
    margin: -10px !important;
  }

  .login_left_sec {
    width: 100% !important;
    height: 240px;
    overflow: hidden;
    align-items: center;
    display: flex;
  }

  .blogDtlPgHdngBox {
    margin-top: 60px;
  }

  .Tabnames ul {
    flex-wrap: wrap;
  }

  /*--- without login Home Page ---*/
  .mobileHidden {
    display: none;
  }

  .navbar-collapse {
    background: #4d122e;
    display: flex;
    text-align: end;
    border-radius: 10px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    justify-content: center;
  }

  .securityReasoninrbx ul {
    list-style-type: none;
    width: 90%;
    font-family: "Montserrat";
  }

  .LandingPageSection~.sbscrptnBox .plans_otrBox {
    justify-content: center;
    gap: 45px;
  }

  .sbscrptninrBox p {
    padding-right: 0;
    margin: 60px 0px;
  }

  .imgtextsctnmain>.container {
    padding-bottom: 0px;
  }

  .LandingPageSection~.imgtextsctn {
    padding: 90px 0px 0px 0px;
  }

  .mainHeader header {
    padding: 25px 10px;
  }

  .ctabox {
    margin: 0px 0px;
  }

  .felxdirection_row-reverse {
    padding: 60px 45px 60px 45px;
  }

  /*-- Hide/Delete Profile --*/

  .setting-hide-delete .dflex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .setting-hide-delete .box2::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    content: " ";
    background: red;
    height: 1px;
    width: calc(100% + 0%);
  }

  .setting-hide-delete {
    margin-bottom: 40px;
    padding: 0px 15px;
  }

  .hidedeletepage .col-lg-12 {
    padding: 0px 30px;
  }

  .hidedeletepage .col-md-3 .text-3 {
    margin-bottom: 15px;
  }

  /*-- profile/request --*/
  .ctaboxmain {
    padding: 80px 20px 75px 20px;
  }

  /*-- profile/settings --*/

  .settingPageBox {
    margin: 20px 0px;
  }

  .ctaboxmain {
    padding: 80px 20px 75px 20px;
  }

  .settingLabel {
    margin: 20px 0px;
    font-size: 18px;
  }

  .settingPageBox .commanheading {
    color: #f2663b;
    font-size: 30px;
  }

  .MyprofileForm textarea {
    width: 100%;
  }

  .Tabnames ul {
    gap: 10px;
    margin: 40px 0px;
    flex-wrap: wrap;
  }

  .goExclusive .plansBx button {
    padding: 10px 15px;
  }

  .plansBx {
    border-radius: 10px;
    padding: 20px 15px;
  }

  .ProfileOverlyDetail {
    padding: 10px;
  }

  /*--header-component ---*/

  .headerCompo .nav-item .active {
    background: none;
  }

  .MatchesPage .headerCompo .nav-icon .icon svg {
    color: #dd2477;
  }

  .MatchesPage .headerCompo .nav-links {
    color: #ffffff;
    padding: 15px 1.5rem;
  }

  .LandingPageSection .headerFixedonScroll .headerCompo .nav-links {
    padding: 10px 0px 16px 0px;
  }

  .headerCompo .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    position: sticky;
    top: 0;
    z-index: 20;
    padding: 0px 0px;
  }

  .headerCompo .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 85%;
    left: 0px;
    display: none;
    transition: all 0.5s ease;
    padding-bottom: 10px;
    padding-top: 20px;
  }

  .headerCompo .nav-menu.active {
    background: linear-gradient(45deg, #dd2477, #f36e36);
    box-shadow: 3px 9px 20px #5757577a;
    padding-bottom: 10px;
    border-radius: 10px;
    top: 85%;
    left: 0px;
    display: block;
    transition: all 0.5s ease;
    z-index: 1;
    padding-top: 20px;
  }

  .headerCompo .nav-icon .icon svg {
    color: #df2e6c;
  }

  .headerCompo .nav-logo img {
    width: 40%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .headerCompo .nav-logo {
    width: 50%;
    margin-top: 0%;
  }

  .headerCompo .nav-container {
    justify-content: left;
  }

  /*-- header component end--*/

  .contactPageSection .commanheading {
    font-size: 22px;
  }

  .BlogCardImgBox {
    height: 100%;
  }

  .offerbar .container {
    max-width: 968px;
  }

  .SubscriptionSection .plans_otrBox .plansBx {
    width: 50%;
  }

  .SubscriptionSection .plans_otrBox {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px;
  }

  .serviceBox {
    padding: 50px 0px;
  }

  .SubscriptionSection .plans_otrBox .plansBx {
    width: 46%;
  }

  /*-- About Us page --*/

  .AboutUsPage .sprHeading {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .AboutUsPage .pl45 {
    padding-left: 0px;
    padding-top: 45px;
  }
}

@media (min-width: 991px) and (max-width: 992px) {
  .contactPageSection .commanheading {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
  }

  .aboutDetail ul li {
    margin-bottom: 5px;
  }

  .profileCardFtr p {
    gap: 2px;
    margin-bottom: 5px;
  }

  .profileCardFtr {
    bottom: 5px;
    border-top: none;
    right: 0px;
    left: 0px;
    gap: 15px;
    padding-top: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }

  p.username {
    margin-bottom: 0px;
  }

  .profileDetail {
    padding: 10px 10px;
  }

  .profileImage {
    min-width: 160px;
    max-width: 160px;
  }

  .NumerologyDetailsPage .prsl-detail {
    margin-bottom: 0px;
  }

  .innerBox,
  .innerBox2 {
    margin: 0px;
  }

  .NumerologyDetailsPage .btn-flex {
    margin-bottom: 50px;
    margin-top: 0px;
  }

  .NumerologyDetailsPage .btn-flex .commonBtn {
    margin: 0px;
  }
}

@media (max-width: 1200px) {
  .navbar-toggler {
    background: none !important;
    border: none !important;
  }

  .navbar-toggler .navbar-toggler-icon {
    background-image: url("../src/assets/images/menu1.png") !important;
  }

  .navbar-toggler .navbar-toggler:focus {
    box-shadow: none !important;
  }
}

@media (max-width: 992px) {
  .MatchesPage .matches-content {
    min-height: 100vh;
  }

  .innerBox::before {
    display: none;
  }

  .MatchesPage>div:not(.headerFixedonScroll) {
    padding: 10px 10px;
  }

  .headerCompo .nav-icon .icon svg {
    color: #df2e6c;
  }

  .headerCompo .nav-logo img {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .headerCompo .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 85%;
    left: 0px;
    display: none;
    transition: all 0.5s ease;
    padding-bottom: 10px;
    padding-top: 20px;
  }

  .headerCompo .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1500px;
    position: relative;
  }

  .headerCompo .nav-menu.active {
    background: linear-gradient(45deg, #dd2477, #f36e36);
    box-shadow: 3px 9px 20px #5757577a;
    padding-bottom: 10px;
    border-radius: 10px;
    top: 85%;
    right: 0px;
    display: block;
    transition: all 0.5s ease;
    z-index: 1;
    padding-top: 20px;
    left: 7%;
    width: 90%;
  }

  .AboutUsPage .navbar-nav .nav-item:nth-child(1) a {
    background: none;
    color: #dd2477;
  }

  .LandingPageSection .mainHeader .nav-item a {
    padding: 10px 20px;
  }

  .nav-icon .icon svg {
    color: #df2e6c;
  }

  .scrolled nav {
    padding: 15px 0px !important;
  }

  /*--- registration ---*/

  /* #progressbar {
    display: none;
  } */

  .reg-logo {
    text-align: center;
  }

  .reg-right-txt {
    justify-content: center;
  }

  .reg-centr-txt {
    margin: 20px 0px;
  }

  /*----*/

  .contactMailIcntext {
    font-size: 14px;
  }

  .MatchesPage .matches-content .ctaboxmain {
    padding: 40px 0;
    margin: 30px 0 400px;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .SubscriptionSection .offerbar {
    margin: 0px 0px !important;
    background: black;
    padding: 0 !important;
  }

  .NumerologyDetailsPage .prsl-detail {
    margin-bottom: 0px;
  }

  .innerBox,
  .innerBox2 {
    margin: 0px;
  }

  .NumerologyDetailsPage .btn-flex {
    margin-bottom: 50px;
    margin-top: 0px;
  }

  .NumerologyDetailsPage .btn-flex .commonBtn {
    margin: 0px;
  }

  .NumerologyDetailsPage .innerBox2 {
    margin: 0px;
  }

  .NumerologyDetailsPage .innerBox {
    margin: 0px;
  }

  .innerBox::before {
    display: none;
  }

  .login_form {
    flex-wrap: wrap;
    justify-content: center;
  }

  .requiredDocsPage .btn-flex button {
    margin-right: 0px;
  }

  .requiredDocsPage .btn-flex {
    margin-bottom: 100px;
    margin-top: 20px;
    margin-right: 0px;
  }

  .requiredDocsPage .terms {
    margin-top: 15px;
    width: 100%;
  }

  .requiredDocsPage #msform .divider {
    margin: 30px 0px 0px 0px;
  }

  #progressbar li:nth-child(1) {
    margin-left: 7%;
  }

  .login-form {
    padding: 0px 0px 20px 0;
    flex-wrap: wrap;
  }

  .login-logo {
    width: 100%;
    margin-bottom: calc(100% / 10);
    text-align: center;
  }

  .login-logo img {
    width: 60%;
    margin-top: 20px;
  }

  .login_right_sec {
    padding: 30px 0px;
  }

  .login_left_sec {
    width: 100% !important;
    height: 260px;
    overflow: hidden;
    align-items: center;
    display: flex;
    padding: 20px 0px;
    margin: auto;
  }

  /*--- without login Home Page ---*/
  .klicksPageSection .navbar-nav .nav-item:nth-child(2) a {
    padding: 30px 8px;
  }

  /*--- without login Home Page ---*/

  .serviceIcnBoxtitle p {
    font-size: 20px;
  }

  .LandingPageSection~.sbscrptnBox .plans_otrBox {
    justify-content: center;
    gap: 45px;
  }

  .sbscrptninrBox p {
    padding-right: 0;
  }

  .sbscrptninrBox {
    margin-top: 0px;
    margin-bottom: 60px;
    text-align: center;
  }

  .LandingPageSection~.imgtextsctn {
    padding: 90px 0px 0px 0px;
  }

  .ctaboxmain {
    background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
    padding: 80px 0px 75px 0px;
    text-align: center;
    color: #fff;
  }

  .LandingPageSection .mainHeader .nav-item a {
    padding: 30px 8px;
  }

  .ctaboxmain {
    padding: 80px 20px 75px 20px;
  }

  /*--  header compo ---*/

  .headerCompo .nav-links {
    padding: 35px 7px;
  }

  .headerCompo .nav-menu {
    width: 90%;
  }

  .Tabnames ul {
    gap: 5px;
    flex-wrap: wrap;
  }

  .Tabnames ul li {
    padding: 5px 10px;
    font-size: 14px;
  }

  /*-- header compo end --*/

  .video-wrapper {
    margin-top: 30px;
  }

  .videoOptionPanel {
    padding-top: 95%;
    margin-left: 19px;
  }

  .video-player {
    height: 100%;
    margin: 0px 0px;
  }

  .SubscriptionSection .plans_otrBox .plansBx {
    width: 50%;
  }

  .SubscriptionSection .plans_otrBox {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .SubscriptionSection .offerbar {
    margin: 0px 0px !important;
    background: black;
    padding: 0 !important;
  }

  #progressbar li:nth-child(1) {
    margin-left: 70px;
  }

  .klicksPageSection .nav-link {
    padding: 25px 7px !important;
  }

  .requiredDocsPage .btn-flex button {
    margin-right: 0px;
  }

  .login_right_sec {
    width: 50%;
    padding: 30px 45px;
  }

  .SubscriptionPage .SubscriptionSection .plans_otrBox .plansBx {
    width: 40%;
  }

  .Tabnames ul {
    gap: 10px;
    flex-wrap: wrap;
  }

  .headerCompo .nav-links {
    padding: 35px 6px;
  }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon.
 This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }

  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}

@media screen and (max-width: 992px) {
  .login_left_sec {
    width: 50%;
  }

  .login-form {
    padding: 0px 0px 10px 0;
  }

  .login-logo {
    margin-bottom: calc(100% / 18);
    margin-top: 15px;
  }

  .login-logo {
    margin-bottom: calc(100% / 10);
    margin-top: 20px;
  }

  .login_right_sec {
    padding: 0px 25px;
  }

  .chatList {
    overflow-x: hidden;
  }

  .chatList .searchBar {
    gap: 5px;
  }

  .userChatbox {
    flex-wrap: wrap;
  }

  .Tabnames ul {
    flex-wrap: wrap;
  }

  .headerCompo .nav-container {
    justify-content: left;
  }

  .headerCompo .nav-item .active {
    color: #ffdd40;
    border: none;
  }

  .headerCompo .nav-links {
    padding: 1rem;
    width: 100%;
    display: table;
    color: #fff;
  }

  .headerCompo .nav-logo .icon {
    width: 2rem;
    height: 2rem;
  }

  .headerCompo .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: #ffdd40;
  }
}

@media (min-width: 992px) {
  header {
    padding: 0px 0px;
  }

  .headerFixedonScroll {
    padding: 0px 0px;
  }
}

@media (max-width: 768px) {
  .mute-speaker svg {
    width: 16px;
  }

  .AudioToggleButton {
    right: 15px;
    bottom: 15px;
  }

  body .toggleSwitch {
    width: 30px;
    height: 30px;
    background: linear-gradient(45deg, #dd2477, #f36e36) !important;
  }

  /*--- without login Home Page ---*/
  .ftrSclLinks ul {
    justify-content: center;
    padding-left: 0px;
  }

  .ftrSclLinks+p {
    text-align: center;
    margin-top: 10px;
  }

  .mainHeader header {
    padding: 25px 10px;
  }

  .navbar-toggler {
    border: none;
  }

  .navbar-toggler-icon {
    background-color: transparent;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .LandingPageSection .mainHeader .nav-item a {
    /* line-height: 40px; */
    height: max-content;
    padding: 10px 20px;
    color: #fff;
  }

  .emoji {
    display: none;
  }

  .login-logo {
    margin-bottom: calc(100% / 20);
    margin-top: 30px;
  }

  .login_right_sec {
    width: 100%;
    padding: 0px 5%;
  }

  .login_form {
    display: flex;
    flex-wrap: wrap;
  }

  .login_left_sec {
    width: 100%;
    height: 250px;
    overflow: hidden;
    object-fit: cover;
    display: flex;
  }

  .login_left_sec img {
    object-fit: cover;
    width: 100%;
  }

  .login_form.changePasswordForm .login_left_sec {
    height: 300px;
  }

  .MatchesPage .login_form.changePasswordForm {
    padding: 0;
  }

  .header-slide svg {
    width: 50px;
  }

  .title-slide h4 {
    font-size: 14px;
  }

  .swiper-testmonials .swiper-slide {
    padding: 20px;
  }

  .section-testmonials {
    flex-direction: column;
    align-items: flex-start;
  }

  .swiper-testmonials {
    width: 100%;
  }

  .serachMatches .password-container button {
    padding: 12px 30px;
  }

  .serachMatches .password-container input {
    padding: 12px 15px;
  }
}

@media only screen and (min-width: 768px) {
.arrowNavigationBar {
    flex-direction: column;
}
.arrowNavigationBar img.firstArrow {
  transform: rotate(270deg) !important;
}
.arrowNavigationBar img.SecondArrow {
  transform: rotate(90deg) !important;
}
}

@media (min-width: 1024px) {
  .login-logo img {
    width: 80%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .profileBox {
    min-height: 370px;
  }

  /*.arrowNavigationBar {
    z-index: -1;
  }*/

  #progressbar li:nth-child(1) {
    margin-left: 92px;
  }
}

@media (min-width: 1400px) {
  .profileBox {
    min-height: 370px;
  }
}
