.SubscriptionTopHeader {
  text-align: center;
}

.commanheading {
  margin-bottom: 0;
}

.py70 {
  padding: 70px 0px !important;
}

.SubscriptionSection .ctaboxmain {
  padding: 0px;
}

.SubscriptionPara {
  text-align: center;
  font-size: 18px;
  padding: 140px 0px !important;
}

.gradientText {
  text-decoration: none;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Montserrat";
  font-weight: 500;
}

.textalignCenter {
  text-align: center;
}

.SubscriptionText .gradientText {
  font-size: 26px;
}

.subpriction_Video_Image img {
  width: 100%;
}

.SubscriptionSection .plans_otrBox {
  display: flex;
  justify-content: center;
  gap: 45px;
}

.SubscriptionSection .plans_otrBox button {
  color: #fff;
  text-decoration: none;
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  padding: 10px 20px;
  text-align: center;
  border-radius: 30px;
  font-family: "Montserrat";
  font-weight: 500;
  border: 2px solid #fff;
  margin: 20px 0px 30px 0px;
}

.SubscriptionSection .plansBx {
  padding: 35px 35px 0px 35px;
}

.mt40 {
  margin-top: 110px;
  margin-bottom: 0;
}

.fontMontserrat {
  font-family: "Montserrat";
}

.SubscriptionSection .offerbar {
  margin: 30px 0px !important;
}

.fontweight700 {
  font-weight: 600;
}

.SubscriptionSection .plans_otrBox button {
  color: #fff;
  text-decoration: none;
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  padding: 12px 30px;
  text-align: center;
  border-radius: 30px;
  font-family: "Montserrat";
  font-weight: 500;
  border: 2px solid #fff;
  margin: 20px 0px 30px 0px;
  box-shadow: 2px 1px 20px 0px #ffffffb0;
  width: 100%;
}

.subpriction_Video_Image video {
  width: 100%;
  height: 70%;
  border-radius: 0px;
}

.subpriction_Video_Image {
  width: 100%;
  margin: auto;
  aspect-ratio: 2/1;
}

.scrolled {
  background-color: #2c0e1ebf;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  transition: background-color 0.5 ease;
  padding: 10px 0px;
  height: 140px;
  right: 0;
  left: 0;
  position: fixed;
  top: 0;
}

.SubscriptionSection {
  /* margin-top: 140px; */
}

.SubscriptionTopHeader .commanheading {
  text-decoration: none;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
