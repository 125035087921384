.modal-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
}

.image-container {
  position: relative;
  margin: 10px;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.delete-button {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  font-size: 16px;
  width: 20px;
  height: 20px;
  padding: 0px 0px 4px 0px;
  font-family: "circular";
  line-height: 5px;
}

.delete-button:hover {
  background-color: #c82333;
}

.storesImagesContainer {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 10px 10px 10px 10px #d4d4d4;
  border: 2px solid #fff;
  aspect-ratio: 1/1;
  width: 20%;
}

.storesImagesContainer img {
  height: auto;
  margin: auto;
  object-fit: contain;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 4px;
  right: 4px;
  border: none;
  padding: 0px;
  cursor: pointer;
  background-color: #f0f0f0;
  display: flex;
  border-radius: 10px;
  color: #fff;
}

.react-responsive-modal-modal {
  max-width: 1200px;
  margin: 8rem;
}

.hidedeletepage .col-lg-12 .text-3 {
  margin-bottom: 15px;
}
