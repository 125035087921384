.profileBox {
  max-width: 1040px;
  margin: 0 auto;
  padding: 0px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.profile {
  display: flex;
  align-items: center;
}

.profileimg {
  width: 300%;
  object-fit: contain;
  aspect-ratio: 1/1;
}

.profile img {
  width: 100%;
  height: 100%;
  border-radius: 0%;
  margin-right: 10px;
  object-fit: cover;
}

.profile h2 {
  font-size: 18px;
  margin: 0;
}
.date {
  font-size: 14px;
  color: #888;
}
.content {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.5;
  font-family: "Montserrat";
}
.contentBox {
  padding: 20px 20px 20px 20px;
}

.py70 {
  padding: 60px 0px !important;
  /* border-bottom: 1px solid #e02b71; */
  margin-bottom: 10px;
}

.contentBox h2 {
  text-decoration: none;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 42px;
  font-weight: 500;
}

.contentBox .date {
  font-family: "Montserrat";
  color: #626262;
  border-bottom: 1px solid #e02b71;
  padding: 15px 0px;
}

.ctabox {
  margin: 120px 0px;
}

.ctaboxmain {
  padding: 80px 0px 75px 0px;
}

.ctaboxtext p {
  margin: 25px 0px 35px 0px;
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: 300;
}

.ctaboxtext h5 {
  font-family: "Montserrat";
  font-size: 28px;
  font-weight: 400;
}

.happystoriePage header {
  /* border-bottom: 2px solid #e91e63; */
}

.arrobxline {
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
  position: relative;
  height: 60px;
  width: 65%;
  margin: auto;
  align-items: center;
}

.arrobxline img {
  height: 28px;
}

.swiper-button-prev img {
  margin-top: -10px;
  margin-left: -10px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}

.arrobxline button {
  padding: 35px 15px;
  width: 40px;
}
