.MyProfile {
  font-family: "Montserrat";
}

.Tabnames ul {
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  gap: 20px 10px;
  list-style-type: none;
  margin: 34px 0px;
  justify-content: flex-start;
  padding: 0;
}

.Tabnames ul li {
  border: 1px solid #dcdcdc;
  padding: 5px 10px;
  border-radius: 100px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.Tabnames ul li.active {
  background: linear-gradient(
    198deg,
    rgba(243, 110, 54, 0.2) 0%,
    rgba(221, 36, 119, 0.2) 100%
  );
  border: 1px solid #d81b60;
}

.logoutTab {
  display: flex;
  justify-content: end;
  text-decoration: none;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tite {
  font-size: 16px;
  font-weight: 600;
}

.viewAll a {
  text-decoration: none;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logoutTab ul li {
  border: 1px solid #dd2477;
}

.aboutDetail ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.aboutDetail ul li {
  margin-bottom: 12px;
  font-size: 14px;
  gap: 15px;
  display: flex;
}

p.username {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.mainTab {
  margin-bottom: 26px;
  margin-top: 8px;
}

.likeAge {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.likeAge img {
  cursor: pointer;
}

.visibiltyNone {
  visibility: hidden;
}

.justifyContentBetween {
  justify-content: space-between;
}

.notificationCard {
  background: #fff;
  border-radius: 20px;
  padding: 15px 10px;
}

.notificationCard {
  background: #fff;
  border-radius: 20px;
  padding: 35px 25px;
  box-shadow: 4px 4px 20px 20px #f3f3f3;
}

.notificationCard ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.NotificationTime {
  font-size: 12px;
  margin-bottom: 5px;
}

.NotificationTitle {
  font-size: 16px;
  font-weight: 600;
}

.notificationCard ul li {
  margin-bottom: 25px;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 20px;
  cursor: pointer;
}

.notificationCard ul li:nth-last-child(1) {
  border: none;
  margin-bottom: 0;
}

.MyprofileForm {
  margin: 20px 0px;
  padding: 10px 40px;
  border-radius: 20px;
  box-shadow: 1px -3px 20px #dfdfdf;
}

.mt10 {
  margin-top: 10px;
}

.grid3,
.grid1 {
  margin: 20px 0px;
}

.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.MyprofileForm_ .accordion-item {
}

.MyprofileForm_ .accordion-button:not(.collapsed)::after {
  background-image: url("");
  transform: scale(3.5);
  rotate: 90deg;
  transition: 0.5s ease;
}

.MyprofileForm_ .accordion-button::after {
  background-image: url("../../../assets/images/arrowCircle.png");
  transform: scale(3.5);
  transition: 0.5s ease;
  background-position-y: 1.5px;
}

.redText {
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.MyprofileForm_ .accordion-body {
  padding: 0px 30px;
}

.MyprofileForm_ label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 6px;
}

.innerExtraPadding {
  padding: 0px 15px;
}

.imgBox img {
  height: 100%;
}

.imgBox {
  width: 150px;
  overflow: hidden;
  border-radius: 50%;
  aspect-ratio: 1/1;
  text-align: center;
}

.my20 {
  margin: 15px 0px;
}

.profileImgAndText h5 {
  margin-bottom: 40px;
  margin-top: 15px;
  font-weight: 600;
}

.saysMatchPrecentange .profileImgAndText .imgBox {
  width: 100%;
  display: flex;
  align-items: center;
  height: 170px;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
}

.MyprofileForm_ .grid3 {
  gap: 80px;
}

.MyprofileForm_ .acdinSubName {
  margin: 10px 0px;
  color: #7a7a7a;
  font-size: 15px;
}

.accordion-button:not(.collapsed) {
  border-bottom: 1px solid #e5405e;
}

.bigHeading {
  font-size: 32px;
  margin-top: 10px;
}

.addphotos_ img {
  width: 60px;
}

.redirectBar {
  margin: 20px 0px;
  border: none;
  box-shadow: -1px 2px 12px #ebebeb;
  padding: 20px 40px;
  border-radius: 15px !important;
  cursor: pointer;
}

.acdinNameBox img {
  width: 28px;
}

.fullProfilePage .ProfileOverlyDetail {
  background: linear-gradient(
    0deg,
    rgb(69 69 69 / 29%) 0%,
    rgb(0 212 255 / 0%) 100%
  );
}

.fullProfilePage .MyprofileForm {
  margin: 20px 0px;
  padding: 10px 40px;
  border-radius: 20px;
  box-shadow: -1px 2px 12px #ebebeb;
}

.fullProfilePage .acdinNameBox img {
  width: 28px;
}

.fullProfilePage .dividerLine {
  background: linear-gradient(45deg, #dd2477, #f36e36);
  height: 1px;
  margin-bottom: 29px;
  margin-top: 29px;
}

.barline {
  padding: 10px 0px;
  border-bottom: 1px solid #dd2477;
}

.barline p {
  margin: 0px;
}

.fullProfilePage .profileBlockButton {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  border: none;
  font-size: 16px;
  color: #fff;
  padding: 4px 20px;
  border-radius: 55px;
}

.fullProfilePage .swiper-button-prev,
.fullProfilePage .swiper-button-next {
  color: #fff;
}

.fullProfilePage .swiper-button-prev:after,
.fullProfilePage .swiper-button-next:after {
  font-size: 18px;
}

.fullProfilePage .MyProfile .grid3,
.grid2,
.grid1 {
  margin: 30px 0px;
}

.fullProfilePage .cusom .grid1 {
  display: grid;
  grid-template-columns: 1fr !important;
  gap: 0;
}

.fullProfilePage .cusom .grid1 .my20 div {
  min-height: 24px;
}
.MatchesPage .matches-content {
  min-height: calc(100vh + 50px);
}

@media (min-width: 1200px) {
  body .profileSliderImage {
    width: 25% !important;
    border-radius: 20px;
    overflow: hidden;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .profileSliderImage {
    width: 80% !important;
    border-radius: 20px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .profileSliderImage {
    width: 47% !important;
    border-radius: 20px;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .profileSliderImage {
    width: 35% !important;
    border-radius: 20px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .profileSliderImage {
    width: 30% !important;
    border-radius: 20px;
  }
}
