/* styles.css */

/* Modal Content */
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  outline: none;
  max-width: 400px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Close Button */
.close-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Close Button Icon */
.close-button::before {
  content: "X";
  font-weight: bold;
  color: #fff;
}

/* Image Container */
.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

/* Image Preview Container */
.image-preview-container {
  position: relative;
  width: calc(33.33% - 10px);
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Image Preview Image */
.image-preview-container img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

/* Remove Button */
.remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 8px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Remove Button Hover Effect */
.remove-button:hover {
  background-color: #333;
}
