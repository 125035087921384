.chatList {
  overflow: scroll;
  height: 605px;
  overflow-x: auto;
}

.chatList .search {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 0px;
  position: sticky;
  top: 0;
  bottom: 10px;
  background: #fff;
  padding-bottom: 10px;
}

.chatList .searchBar {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 50px;
  padding: 10px 15px;
  border: 1px solid #d4d4d4;
}

.chatList .searchBar input {
  background-color: transparent;
  border: none;
  outline: none;
  color: grey;
  flex: 1;
}

.chatList .searchBar img {
  width: 20px;
  height: 20px;
}

.chatList .add {
  width: 36px;
  height: 36px;
  background-color: rgba(17, 25, 40, 0.5);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.chatList .item {
  cursor: pointer;
  border-radius: 15px;
}

.chatList .active {
  background-color: #ffc0cb6b !important;
}

.chatList .item img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.chatList .item .texts {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

.chatList .item .texts span {
  font-weight: 600;
  font-size: 17px;
}

.chatList .item .texts p {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
}

.divider {
  border-bottom: 0.2px solid #c4c4c4 !important;
  border-top: 0px !important;
  margin: 10px 0px;
}

.userChatbox {
  display: flex;
  gap: 20px;
  padding: 15px 20px;
  background: #ffd6e6;
  border-radius: 10px;
  align-items: center;
}

.list {
  display: flex;
  flex-direction: column;
  box-shadow: 1px -1px 20px 20px #d4d4d44d;
  padding: 20px;
  border-radius: 20px;
}
