.NumerologyDetailsPage label {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
}

.prsl-detail-heading {
  font-weight: 500;
  font-size: 19px;
  color: #e1326b;
}

.NumerologyDetailsPage .detail-title {
  gap: 0px;
}

.border {
  display: none;
}

.innerBox {
  margin-right: 60px;
  position: relative;
}

.innerBox::before {
  position: absolute;
  content: " ";
  top: 0;
  right: -79px;
  bottom: 0;
  width: 1px;
  background-color: #d4d4d4;
}

.innerBox2 {
  margin-left: 60px;
}

.innerBox,
.innerBox2 {
  margin-top: 30px;
}

.NumerologyDetailsPage input {
  margin-bottom: 30px !important;
}

.NumerologyDetailsPage .dropdown-menu {
  margin-bottom: 35px !important;
}

#msform .dflex {
  gap: 20px;
}

.btn-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 50px;
}

.commonBtn {
  margin-right: 50px;
}

.mb20 {
  margin-bottom: 20px;
}

.prsl-detail-heading {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 17px;
}

.btn-trans {
  color: #dd2477 !important;
}

.modalClass {
  text-align: center;
}

.modalClass img {
  display: block;
  text-align: center;
  margin: auto;
  width: 35px;
}

.kundaliDetails .insideAccordian {
  margin: 20px 0px;
  padding: 20px 40px;
  border-radius: 20px;
  box-shadow: 1px -3px 20px #dfdfdf;
}

.kundaliDetails .MyprofileForm {
  margin: initial;
  padding: initial;
  border-radius: initial;
  box-shadow: initial;
}

.kundaliDetails .accordion-button:not(.collapsed) {
  border-bottom: none;
}

.kundaliDetails .insideAccordian .accordion-button:not(.collapsed) {
  box-shadow: none !important;
  padding: 22px 0px 10px 0px;
}

.kundaliDetails .dividerLine {
  background: linear-gradient(45deg, #dd2477, #f36e36);
  height: 1px;
  margin-bottom: 29px;
  margin-top: 29px;
}

.kundaliDetails .reg-centr-txt h1,
.NumerologyDetailsPage .reg-centr-txt h1 {
  font-size: 34px;
  font-family: "Quiche Display Regular";
  font-weight: 500;
}

.grid2Reverse {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;
}
