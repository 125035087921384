.NumerologyDetailsPage label {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #626262;
}
.prsl-detail-heading {
  font-weight: 500;
  font-size: 19px;
  color: #e1326b;
}

.NumerologyDetailsPage .detail-title {
  gap: 0px;
}

.border {
  display: none;
}

.innerBox {
  margin-right: 60px;
  position: relative;
}

.innerBox::before {
  position: absolute;
  content: " ";
  top: 0;
  right: -79px;
  bottom: 0;
  width: 1px;
  background-color: #d4d4d4;
}

.innerBox2 {
  margin-left: 60px;
}

.innerBox,
.innerBox2 {
  margin-top: 30px;
}

.NumerologyDetailsPage input,
.NumerologyDetailsPage .select {
  margin-bottom: 30px;
}

#msform .dflex {
  gap: 20px;
}

.btn-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 50px;
}

.commonBtn {
  margin-right: 50px;
}

.btn-trans {
  color: #dd2477 !important;
}

/*--*/

.NumerologyDetails .accordion {
  background-color: #fff;
}

.NumerologyDetails .accordion-item {
  margin: 0px;
  padding: 0px;
  background: none;
  box-shadow: none;
}

.NumerologyDetails .accordion-header {
  padding-right: 0px;
  padding-left: 0px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.NumerologyDetails .accordion-button {
  border-bottom: none;
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  padding: 20px 20px;
  border-radius: 20px !important;
}

.NumerologyDetails .accordion-button:has(.collapsed) {
  border-bottom: 1px solid #217f37;
  background: blue;
}

.NumerologyDetails .accordion-header .accordion-collapse.collapse {
  margin-top: 40px;
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
}

.NumerologyDetails .accordion-collapse.collapse.show {
  background: white;
  box-shadow: none;
  border-radius: 10px;
}

.NumerologyDetails .acdinNameBox p {
  color: #fff;
}

.NumerologyDetails .insideAccordian .accordion-body {
  padding: 0px;
}

.NumerologyDetails .insideAccordian .accordion-button {
  background: none;
  padding: 0px 0px;
}

.NumerologyDetails .insideAccordian .accordion-header {
  margin-top: 0px;
  margin-bottom: 0px;
}

.NumerologyDetails .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.NumerologyDetails .insideAccordian .accordion-collapse.collapse.show {
  box-shadow: none;
}

.NumerologyDetails .insideAccordian .acdinNameBox p {
  color: #dd2477;
}

.insideAccordian .accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.NumerologyDetails .insideAccordian {
  box-shadow: none;
  padding: 0px;
  margin-bottom: 20px;
}

.insideAccordian .grid3 {
  margin: 20px 0px;
}

.accordion-header:not(.collapsed) .insideAccordian {
  display: none;
}

.InnerinsideAccordian .accordion-body {
  padding: 0px;
}

.NumerologyDetails .accordion-button:not(.collapsed)::after {
  background-image: url("./../../../assets/images/whiteDropArrowOpenTab.svg");
  background-size: auto;
  background-position-y: 4px;
}

.NumerologyDetails .accordion-button::after {
  background-image: url("./../../../assets/images/whiteDropArrowOpenTab.svg");
  background-size: auto;
  background-position-y: 4px;
}

.NumerologyDetails .reg-centr-txt h1 {
  font-size: 34px;
  font-family: "Quiche Display Regular";
  font-weight: 500;
}

.NumerologyDetails button.btn-trans {
  font-size: 35px;
}

.NumerologyDetails .back-btn {
  display: flex;
  justify-content: start;
  margin-right: 15px;
}

.NumerologyDetails .MyprofileForm {
  margin: 20px 0px;
  padding: 10px 40px;
  border-radius: 20px;
  box-shadow: 1px -3px 20px #dfdfdf;
}

.NumerologyDetails .grid1 {
  grid-template-columns: 1fr;
}
