.addressInfo .MyprofileForm {
}

.addressInfo .grid2 .flex label + img,
.addressInfo .grid3 .flex label + img,
.addressInfo .grid1 .flex label + img {
  margin-left: 5px;
}

/* .addressInfo .grid2 .flex label {
  font-size: 12px;
} */

.addressInfo .grid2 {
  grid-template-columns: 1fr;
  margin: 0px 0px;
  gap: 0px;
}

.addpadding {
  padding: 0px 10px;
}

.MatchesPage {
  font-family: "Montserrat";
}

.profileImgInrBox {
  max-height: 500px;
  border-radius: 20px;
  overflow: hidden;
}

.profileImgInrBox img {
  width: 100%;
  height: fit-content;
}

.dflex {
  display: flex;
}

.acdinNameBox {
  width: 100%;
  justify-content: space-between;
}

.acdinNameBox .dflex {
  gap: 5px;
}

.acdinNameBox img {
  width: 20px;
}

.acdinNameBox p {
  margin-bottom: 0px;
  margin-right: 15px;
}

.acdinName {
  color: #000;
  font-weight: 600;
}

.accordion-button:not(.collapsed) {
  background-color: #fff;
}

.acdinedit {
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
}

.accordion-button::after {
  background-image: url("../../../assets/images/SVGaccordianOpen.svg");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../../assets/images/SVGaccordianClose.svg");
}

.accordion-button:focus {
  border-color: none;
  box-shadow: none;
}

.accordion-item {
  margin: 20px 0px;
  border: none;
  box-shadow: 0px 0px 20px 20px #ececec;
  padding: 6px;
  border-radius: 15px !important;
}

.textAreaBox textarea {
  border-radius: 10px;
  padding: 10px 20px;
}

.MyProfile .grid3,
.grid2,
.grid1 {
  margin: 0px 0px;
}

.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.grid2 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
}

.MyprofileForm {
  margin: 0;
}

.MyprofileForm textarea {
  margin-bottom: 20px;
  width: 100%;
}

.MyprofileForm label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 6px;
}

.MyProfile .dropdown-menu {
  display: block;
  color: #000;
  border-radius: 28px;
  border: 1px solid #d9d9d9;
  padding: 15px;
  font-size: 16px;
  font-family: "Montserrat";
  box-sizing: border-box;
  outline: 0;
  font-weight: 400;
  line-height: 19.5px;
  margin-bottom: 20px;
  width: 100%;
  position: initial;
}

.disableinputbox {
  padding: 12px 20px;
  background: #eaeaea;
  margin-bottom: 20px;
  border-radius: 50px;
}

.disableinputbox .dflex {
  gap: 4px;
}

.accordion-button {
  padding-right: 0px;
  padding-left: 0px;
}

.accordion-button:not(.collapsed) {
  border-bottom: 1px solid #d3d3d3;
}

.accordion-header {
  padding-right: 30px;
  padding-left: 30px;
}

.mySwiper {
  /* position: relative; */
}

.ProfileOverlyDetail {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: " ";
  background: linear-gradient(0deg, rgb(34 33 33) 0%, rgb(0 212 255 / 0%) 100%);
  z-index: 9999;
  display: grid;
  grid-template-rows: auto;
  padding: 40px;
  border-radius: 20px;
}

.contentNumber {
  display: flex;
  justify-content: end;
}

.nameContent {
  align-items: end;
}

.nameNAge {
  color: #fff;
  font-size: 24px;
}

.addphotos {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  padding: 11px 15px;
  border-radius: 60px;
  color: #fff;
  cursor: pointer;
}

.addphotos img {
  width: 26px;
}

.contentNumber span {
  display: inline-block;
  background: #e6e6fa75;
  height: fit-content;
  padding: 10px 20px;
  border-radius: 30px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
}

.nameNAge .userName {
  margin: 0;
  font-weight: 600;
  font-size: 31px;
}

.addphotos .dflex {
  gap: 14px;
}

.goExclusive {
  margin-top: 40px;
}

.goExclusive .plansBx .planHaeading {
  text-align: left;
}

.goExclusive .plansBx .planHaeading p {
  font-family: "Montserrat";
}

.goExclusive .plansBx button {
  color: #fff;
  text-decoration: none;
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  padding: 12px 30px;
  text-align: center;
  border-radius: 30px;
  font-family: "Montserrat";
  font-weight: 500;
  border: 2px solid #fff;
  margin: 20px 0px 0px 0px;
  box-shadow: 2px 1px 20px 0px #ffffffb0;
  width: 100%;
}

.MyProfile .MyprofileForm {
  padding: 0px;
  box-shadow: none;
}

.MyProfile .acdinNameBox img {
  width: 20px;
}

.MyProfile .swiper-button-prev,
.MyProfile .swiper-button-next {
  color: #fff;
}

.MyProfile .swiper-button-prev:after,
.MyProfile .swiper-button-next:after {
  font-size: 18px;
}

.MatchesPage .success-msg {
  margin-top: -2px;
  margin-bottom: 0px;
}

.privacyPage .privacy-policy {
  padding: 50px 0;
}
.privacyPage .privacy-policy .section p {
  font-family: 'Montserrat';
}
.privacyPage .privacy-policy .section {
  margin-bottom: 30px;
}
.commandescription .button {
  display: inline-block;
  width: auto;
}
.commandescription .button:hover {
  background: #939393;
  color: #fff;
}
.MyprofileForm.document-info .grid2 {
  margin: 0;
}
.form-card.order-summary  .dflex div {
  max-width: calc(50% - (10px * 1/2));
  width: 100%;
}
.form-card.order-summary .space div {
  max-width: 100%;
}
.paymentPage {
  min-height: 100vh;
}
.MyprofileForm.document-info .grid2 .mbn18 {
  margin: 0 0 20px;
}

.dob-input {
  position: relative;
}
.dob-input #myDateLabel {
  display: none;
}
.dob-input::before {
  content: "\f133";
  position: absolute;
  font-family: "FontAwesome";
  font-size: 20px;
  right: 20px;
  top: 14px;
  line-height: normal;
}

.dob-input input[type="date"]::-webkit-inner-spin-button,
.dob-input input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}

.dob-input #dob {
  -webkit-appearance: none;
  -moz-appearance: none;
  -moz-appearance: textfield;
}
.videoPerviewModal center {
  position: relative;
}
.videoPerviewModal center .previewplay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto !important;
  height: auto !important;
}
.videoPerviewModal center .previewplay .playicon {
  font-size: 30px !important;
}