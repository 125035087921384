.modalClass .ReactModal__Overlay {
  background-color: #fff;
}
.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border: 1px solid #ccc; */
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  border: 1px solid #f0653e;
  border-radius: 20px;
  box-shadow: 20px 20px 20px #d4d4d4;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.commonBtn {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  color: #fff;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 40px;
  border: 1px solid #fff;
  box-shadow: 0px 6px 40px 0px #ffffff5e;
  font-family: "Montserrat";
  font-weight: 600;
}

.modalClass button {
  display: block;
  margin: auto;
  width: 220px;
}

.modalClass {
  text-align: center;
  font-family: "Montserrat";
}

.modalClass p {
  margin-bottom: 0px;
}

.Modal_Desc {
  margin: 5px 0px 25px 0px;
  font-size: 14px;
  color: #191919;
}

.Modal_heading {
  font-weight: 700;
  margin-top: 30px;
}

.cancleBtn {
  border: none;
  background: none;
  margin-top: 80px;
  top: 10px;
  position: relative;
  color: #e1326a;
  font-weight: 500;
}
