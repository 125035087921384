/* Simplified CSS */

.chat {
  flex: 2 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  height: 650px;
  overflow: auto;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 1px -1px 20px 20px #d4d4d44d;
}

.chatLoader .fullbodyLoaderBgColor {
  opacity: 0.5;
}

.chat .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd35;
  background: #ffd6e6;
  margin-bottom: 20px;
  padding: 15px 20px;
  position: sticky;
  right: 0;
  left: 0;
  width: 100%;
  top: 0;
}

.chat .top .user {
  display: flex;
  align-items: center;
  gap: 20px;
}

.chat .top .user img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.chat .top .user .texts {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.chat .top .user .texts span {
  font-size: 16px;
  font-weight: bold;
}

.optionDots img {
  width: 6px;
}

.chat .top .user .texts p {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

/* ... (other styles) ... */

.chat-container {
  /* width: 80vw;
  height: 90vh;
  background-color: white;
  backdrop-filter: blur(19px) saturate(180%);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  display: flex; */
}

.message.own .texts p {
  background: #e02b71;
  margin-bottom: 20px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-bottom: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 30px;
  color: #fff;
}

.message .texts p {
  background: #ffd6e6;
  margin-bottom: 20px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-bottom: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 30px;
  color: #000;
}

.message.own .message {
  display: flex;
  justify-content: end;
  margin-bottom: 30px;
}

.message.own {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

.message {
  margin-bottom: 20px;
  display: flex;
}

.center {
  padding: 20px;
  height: 80%;
  display: flex;
  justify-content: end;
  flex-direction: column;
}

.texts {
  display: flex;
  flex-direction: column;
}

.message.own .texts span,
.message .texts span {
  font-size: 12px;
  margin-top: 3px;
  text-align: right;
}

.message.own .texts span {
  text-align: right;
}

.message .texts span {
  text-align: left;
}

.bottom {
  padding: 15px;
  display: flex;
  position: sticky;
  bottom: 0;
  background: #fff;
  border: 2px solid #fff;
}

.bottom input[type="text"] {
  width: 100%;
  border-radius: 50px;
  padding: 10px 150px 10px 25px;
  border: 1px solid #e02b71;
  position: relative;
  background-color: #fdf7f9;
}

.emoji {
  position: absolute;
  right: 80px;
  top: 23px;
  cursor: pointer;
}

.emoji img {
  width: 25px;
}

.sendButton {
  position: absolute;
  right: 40px;
  margin-top: 10px;
  width: 22px;
  cursor: pointer;
}

.picker {
  position: absolute;
  right: 0px;
  bottom: 30px;
}

/* width */
::-webkit-scrollbar {
  width: 0.2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.onlineIndicator {
  padding: 5px;
  background-color: green;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  display: block;
}

.optionDots {
  cursor: pointer;
}

.optionBox {
  display: block;
}

.optionDots ul {
  margin: 0;
  list-style-type: none;
  position: absolute;
  background: #fff;
  padding: 10px 20px 10px 15px;
  border-radius: 10px;
  right: 23px;
  box-shadow: 1px 0px 20px 4px #8e8e8e40;
}

.optionDots ul li {
  padding: 10px 30px 10px 0px;
  font-weight: 500;
  cursor: pointer;
  border-bottom: 1px solid #c6c6c6;
}

.optionDots ul li:last-child {
  border: none;
}

.picker input {
  padding-left: 32px;
  padding-top: 11px;
}

.message.own .texts {
  gap: 8px;
  justify-content: end;
  align-items: end;
}

.message.own .texts .dflex img {
  display: block;
}

.message.own .texts .dflex img {
  display: block;
  align-items: end;
  margin-left: 5px;
}

.message .texts .dflex img {
  display: none;
}
