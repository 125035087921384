.MyProfile {
  font-family: "Montserrat";
}

.Tabnames ul {
  display: flex;
  text-decoration: none;
  gap: 20px;
  list-style-type: none;
  margin: 50px 0px;
  justify-content: flex-start;
  padding: 0;
}

.Tabnames ul li {
  border: 1px solid #dcdcdc;
  padding: 5px 20px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.Tabnames ul li.active {
  background: linear-gradient(
    198deg,
    rgba(243, 110, 54, 0.2) 0%,
    rgba(221, 36, 119, 0.2) 100%
  );
  border: 1px solid #d81b60;
}

.logoutTab {
  display: flex;
  justify-content: end;
  text-decoration: none;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tite {
  font-size: 16px;
  font-weight: 600;
}

.viewAll a {
  text-decoration: none;
  background: linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logoutTab ul li {
  border: 1px solid #dd2477;
}

.aboutDetail ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.aboutDetail ul li {
  margin-bottom: 12px;
  font-size: 14px;
  gap: 15px;
  display: flex;
}

p.username {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.mainTab {
  margin-bottom: 26px;
  margin-top: 8px;
}

.likeAge {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.likeAge img {
  cursor: pointer;
}

.visibiltyNone {
  visibility: hidden;
}

.justifyContentBetween {
  justify-content: space-between;
}

.notificationCard {
  background: #fff;
  border-radius: 20px;
  padding: 15px 10px;
}

.notificationCard {
  background: #fff;
  border-radius: 20px;
  padding: 35px 25px;
  box-shadow: 4px 4px 20px 20px #f3f3f3;
}

.notificationCard ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.NotificationTime {
  font-size: 12px;
  margin-bottom: 5px;
}

.NotificationTitle {
  font-size: 16px;
  font-weight: 600;
}

.notificationCard ul li {
  margin-bottom: 25px;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 20px;
  cursor: pointer;
}

.notificationCard ul li:nth-last-child(1) {
  border: none;
  margin-bottom: 0;
}

.MatchesPage .profileCardFtr {
  /* visibility: hidden; */
}

.serachMatches .password-container button {
  width: auto;
  font-size: 16px;
  padding: 15px 60px;
  margin-top: 0px;
  height: fit-content;
}

.serachMatches .password-container {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.serachMatches .password-container input,
#file-input-label {
  color: #000;
  border-radius: 28px;
  border: 1px solid #d9d9d9;
  padding: 15px;
  font-size: 16px;
  font-family: "Montserrat";
  display: block;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
  font-weight: 400;
  line-height: 19.5px;
}
