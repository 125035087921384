.grid2Reverse {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;
}

.paymentBlockHeading {
  border-bottom: 2px solid #d4d4d4;
}

.paymentBlockHeading p {
  font-size: 28px;
  color: #dd2477;
  font-weight: 600;
}

.coupounCode {
  font-size: 14px !important;
}

.Blabel {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 18px;
}

.desclabel {
  font-weight: 500;
  color: #aeaeae;
}

.totalText {
  margin: 0px;
}

.positionRelativeBox input {
  position: relative;
}

.positionRelativeBox img {
  position: absolute;
  right: 30px;
  z-index: 1;
  margin-top: 17px;
  cursor: pointer;
}

.exclcvPrizeBox {
  border-bottom: 2px solid #d4d4d4;
  margin-bottom: 30px;
  padding-bottom: 20px;
  padding-top: 15px;
}

.paymentPage #msform .divider {
  margin: 10px 0px 30px 0px;
}

.FinalPrize p {
  font-size: 30px;
  font-weight: 600;
  color: #dd2477;
}

.paymentPage .button {
  width: 100%;
}

.paymentPage .Verify_link .btn-trans {
  background: none;
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 14px;
  color: #dd2477;
}

.backarrowbar {
  margin: 40px auto;
  cursor: pointer;
  width: 50%;
}

.backarrowbar img {
  transform: rotate(180deg);
  width: 30px;
}

.PaymentSuccessPage .heading p {
  font-size: 28px;
  font-family: "Montserrat";
  background: -webkit-linear-gradient(45deg, #dd2477, #f36e36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  border-bottom: 1px solid #e23468;
  padding-bottom: 30px;
  margin-bottom: 40px;
}

.paymentPage .innerBox {
  margin-right: 60px;
  position: relative;
  width: 50%;
  margin: auto;
}

.paymentPage .innerBox::before {
  display: none;
}
