.rsa {
  border-bottom: 2px solid #e02b71;
  margin: 40px 0px;
}

.rsa {
  border-bottom: 2px solid #e02b71;
  margin: 40px 0px;
}

.rsa ul li {
  cursor: pointer;
}

.rsa ul li.active {
  padding: 0px 0px 20px 0px;
  border-bottom: 2px solid #e02b71;
}

.rsa ul a {
  color: #000;
}

.rsa ul {
  display: flex;
  gap: 40px;
  padding: 0;
  list-style-type: none;
  margin: 0px 0px 0px 45px;
}

.RequestPage .profileCardFtr {
  display: flex;
  border-top: 1px solid #d9d9d9;
  right: 50px;
  left: 25px;
  gap: 40px;
  padding-top: 15px;
  padding-right: 0px;
  padding-left: 0px;
  align-items: center;
  height: 60px;
  justify-content: center;
}
