/* UploadImageModal.css */
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.Modal h2 {
  margin-bottom: 20px;
}

.Modal input[type="file"] {
  margin-bottom: 20px;
}

.Modal button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.myprofileImagesUpload input {
  border: 1px dashed blue;
  background-color: #f8f8f8;
  cursor: pointer;
  padding-bottom: 100px;
  padding-left: 90px;
  padding-right: 40px;
  padding-top: 24px;
  width: 300px;
}

.myprofileImagesUpload input:before {
  content: "Drop file here or click to upload";
  font-size: 1.1em;
  font-weight: 500;
  color: black;
  margin-bottom: 20px;
  margin-left: -56px;
}

.uploadBox input::file-selector-button {
  display: block;
  margin: auto;
  text-align: center;
  border-bottom: 2px solid #dd2477;
  border-top: none;
  border-right: none;
  border-left: none;
}

.uploadBox label {
  display: block;
}

.uploadBox {
  text-align: center;
  margin-top: 45px;
  font-family: sans-serif;
}

.uploadBox button {
  color: #fff;
  text-decoration: none;
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  padding: 12px 30px;
  text-align: center;
  border-radius: 30px;
  font-family: "Montserrat";
  font-weight: 500;
  border: 2px solid #fff;
  margin: 20px 0px 0px 0px;
  box-shadow: 2px 1px 20px 0px #ffffffb0;
  width: 100%;
}

.uploadBox button:disabled {
  color: #fff;
  text-decoration: none;
  background: linear-gradient(73.78deg, #dd2477 0%, #f36e36 100%);
  padding: 12px 30px;
  text-align: center;
  border-radius: 30px;
  font-family: "Montserrat";
  font-weight: 500;
  border: 2px solid #fff;
  margin: 20px 0px 0px 0px;
  box-shadow: 2px 1px 20px 0px #ffffffb0;
  width: 100%;
  opacity: 0.5;
}

.uploadBox input[type="file"] {
  color: transparent;
  /* margin-top: 15px; */
  /* border: 1px solid red; */
  padding-top: 25px;
  width: 100%;
  background: #fdeef3;
  border-radius: 10px;
}

.react-responsive-modal-modal {
  text-align: center;
}

.react-responsive-modal-modal h2 {
  text-align: center;
  border-bottom: 0.5px solid rgb(255, 131, 131);
  padding-bottom: 15px;
}
